/**
 * @generated SignedSource<<38d966add558ec935deebc95dfaa80ad>>
 * @lightSyntaxTransform
 * @nogrep
 * @codegen-command: npm run load-schema
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type StuSettingsUpdatetrialstep2Mutation$variables = {
  homework: number;
  password: string;
  studentAge: number;
  teacherGender: number;
  teachingStyle: number;
  timezone: string;
};
export type StuSettingsUpdatetrialstep2Mutation$data = {
  readonly stu_settings_updatetrialstep2: {
    readonly data: {
      readonly user: {
        readonly dob: any | null;
        readonly id: string;
        readonly password_set: number;
        readonly pref_teacher_gender: number | null;
        readonly timezone_set: number;
        readonly user_settings: {
          readonly homework: any | null;
          readonly id: string;
          readonly teacher_style: any | null;
        } | null;
      };
    };
    readonly errors: ReadonlyArray<string | null> | null;
    readonly success: boolean;
  } | null;
};
export type StuSettingsUpdatetrialstep2Mutation = {
  response: StuSettingsUpdatetrialstep2Mutation$data;
  variables: StuSettingsUpdatetrialstep2Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "homework"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "password"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "studentAge"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teacherGender"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "teachingStyle"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "timezone"
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v7 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "homework",
        "variableName": "homework"
      },
      {
        "kind": "Variable",
        "name": "password",
        "variableName": "password"
      },
      {
        "kind": "Variable",
        "name": "studentAge",
        "variableName": "studentAge"
      },
      {
        "kind": "Variable",
        "name": "teacherGender",
        "variableName": "teacherGender"
      },
      {
        "kind": "Variable",
        "name": "teachingStyle",
        "variableName": "teachingStyle"
      },
      {
        "kind": "Variable",
        "name": "timezone",
        "variableName": "timezone"
      }
    ],
    "concreteType": "StuSettingsUpdatetrialstep2Output",
    "kind": "LinkedField",
    "name": "stu_settings_updatetrialstep2",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "success",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "errors",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "StuSettingsUpdatetrialstep2Outputdata",
        "kind": "LinkedField",
        "name": "data",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "users",
            "kind": "LinkedField",
            "name": "user",
            "plural": false,
            "selections": [
              (v6/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "dob",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "password_set",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "timezone_set",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "pref_teacher_gender",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "user_settings",
                "kind": "LinkedField",
                "name": "user_settings",
                "plural": false,
                "selections": [
                  (v6/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "teacher_style",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "homework",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "StuSettingsUpdatetrialstep2Mutation",
    "selections": (v7/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Operation",
    "name": "StuSettingsUpdatetrialstep2Mutation",
    "selections": (v7/*: any*/)
  },
  "params": {
    "cacheID": "1f89590b8d091b9b0784bf3e5bea9303",
    "id": null,
    "metadata": {},
    "name": "StuSettingsUpdatetrialstep2Mutation",
    "operationKind": "mutation",
    "text": "mutation StuSettingsUpdatetrialstep2Mutation(\n  $studentAge: Int!\n  $teacherGender: Int!\n  $teachingStyle: Int!\n  $homework: Int!\n  $password: String!\n  $timezone: String!\n) {\n  stu_settings_updatetrialstep2(studentAge: $studentAge, teacherGender: $teacherGender, teachingStyle: $teachingStyle, homework: $homework, password: $password, timezone: $timezone) {\n    success\n    errors\n    data {\n      user {\n        id\n        dob\n        password_set\n        timezone_set\n        pref_teacher_gender\n        user_settings {\n          id\n          teacher_style\n          homework\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "d516aedc9c37e6dba43590a2deee7e87";

export default node;
