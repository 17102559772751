import React from 'react';
import {
  Box, Button, Card, CardContent, Ty,
} from '@languageconvo/wcl';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// TODO: design (unless we no longer need this component)
export const PackageNotFound = () => {
  const { t } = useTranslation('purchasePayPage');
  const navigate = useNavigate();

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Card>
        <CardContent cp={{ sx: { width: '100%' } }}>
          <Ty v="h2">No package found for this pack id</Ty>
          <br />
          <Box sx={{ textAlign: 'center' }}>
            <Button size="small" color="accentOrange1" onClick={() => navigate('/purchase/home')}>{t('Choose Your Package')}</Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};
