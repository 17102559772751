import {
  styled, WCLThemeProps, Box,
} from '@languageconvo/wcl';
import { HEADER } from '../../../../../config';
import { sitedata } from '../../../../../utils/sitedata';

// navbar on mobile devices will be at the bottom
export const NavbarContainer = styled('div')(({ theme, pathname }: WCLThemeProps & any) => ({
  // only show this bottom bar on xs, sm, md
  [theme!.breakpoints.up('lg')]: {
    display: 'none',
  },
  width: '100%',
  height: `${HEADER.MOBILE_BOTTOMBAR_HEIGHT}px`,
  // position the bar to the bottom of the page
  position: 'absolute',
  left: 0,
  bottom: 0,
  // not sure why nowrap is needed. multiple tutorials online listed it
  whiteSpace: 'nowrap',
  // this removes a blue background color that was happening when clicking on
  // navbar items
  WebkitTapHighlightColor: 'transparent',
  overflow: 'hidden',
  background: `hsl(${theme?.ourTheme.colors.app.navbar})`,
  // centers the div. only applicable on larger screens, where the width of the screen
  // is wider than all the icon divs put together
  textAlign: 'center',
  // create a top border line
  borderTopStyle: 'solid',
  borderTopWidth: '1px',
  borderTopColor: `hsl(${theme?.ourTheme.colors.app.page})`,
  // in the group classroom, on mobile devices (xs, sm, md) don't show bottom bar.
  // so that we have more room for displaying video
  [theme!.breakpoints.down('lg')]: {
    display: pathname === sitedata.url.app.groupclassroomDt.pathName ? 'none' : 'visible',
  },
}));

export const MobileItemContainer = styled(Box)(({ theme }: WCLThemeProps) => ({
  // width on mobile (xs)
  width: '25%',

  // width on sm and md
  [theme!.breakpoints.up('sm')]: {
    width: '120px',
  },

  height: '100%',
  // this centers everything. only applicable on larger screens where the screen
  // width is larger than the icons put together
  textAlign: 'center',
  // dont think this is necessary, the same prop on MobileNavbar stops the blue
  // highlight on click. adding it here just to be sure
  WebkitTapHighlightColor: 'transparent',
  // ensurs divs align next to rather than on top of each other
  display: 'inline-block',
}));

export const MobileItemContainerIcon = styled('div')({
  marginTop: '12px',
  textAlign: 'center',
});

export const MobileItemContainerText = styled('div')(({ active }: any) => ({
  fontSize: '13px',
  textAlign: 'center',
  ...(active === 1 && {
    fontWeight: '600',
  }),
}));

export const MobileIcon = styled('img')(() => ({
  width: '24px',
  height: '24px',
}));
