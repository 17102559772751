import * as Sentry from '@sentry/react';
import navConfig from '../layouts/dashboard/navbar/NavbarConfig';
import {
  LcTchSettings, LcStuSecurity, THEME, LANG, NAVBAR, PAGES, FindTeacher, GroupLesson,
  AppVersionData
} from './lcStorageInterface';
import { studentLocalStorageKeys } from './sitedata/studentLocalStorageKeys';

/** this utility file contains the localStorage setter/getter funcitons.
 * For each key (lc_stusecurity, lc_stusettings), we have getter/setter. It
 * means, each funciton have its own logic, 
 * 
 * IMPORTANT: In future, if anyone wants to create new key in localStorage, create
 * getter/setter for that key and typeguard (validity checker)
 * navConfig have all info related to our sideNav, we are looping on navConfig
 * and displaying sideNav data in our app.
 */

// #region lc_stusecurity getter/setter

export const getStuSecurityFromLCStorage = ():LcStuSecurity => {
  const storedValues:string | null = localStorage.getItem(studentLocalStorageKeys.studentSecurity);
  let obj: LcStuSecurity = { jwt: '' };
  if (storedValues) {
    try {
      obj = JSON.parse(storedValues);
      if (obj
        && typeof obj === 'object'
        && 'jwt' in obj
        && typeof obj.jwt === 'string') {
        return obj;
      }
    } catch (error) {
      Sentry.captureException(error);
    }
    return obj;
  }
  return {};
};

export const setStuSecurityToLCStorage = (node: LcStuSecurity) => {
  if (node && node.jwt) {
    localStorage.setItem(studentLocalStorageKeys.studentSecurity, JSON.stringify(node));
  }
};

// #endregion lc_stusecurity getter/setter

// #region lc_stusettings getter/setter

/*
  Getting lc_stusettings from local storage. You may see its return type.
  This will always return valid LcTchSettings obj
  For new students, we'r returning the default values.
  For existing students, we'r checking value validity via typegaurd and
  Reconstruct a valid LcTchSettings object based on the parsed value
*/
export const getStuSettingsFromLCStorage = ():LcTchSettings => {
  const storedValue = localStorage.getItem(studentLocalStorageKeys.studentSettings);
  let parsedValue: LcTchSettings = defaultStuSettings;

  if (storedValue) {
    try {
      // it can throw error if not valid JSON
      parsedValue = JSON.parse(storedValue);
      // check isValidPartialLcStuSettings comments.
      if (isValidPartialLcStuSettings(parsedValue)) {
        // Reconstruct a valid LcTchSettings object based on the parsed value
        const reconstructedValue: LcTchSettings = {
          navbar: { ...defaultStuSettings.navbar, ...parsedValue.navbar },
          theme: { ...defaultStuSettings.theme, ...parsedValue.theme },
          lang: { ...defaultStuSettings.lang, ...parsedValue.lang },
          pages: { ...defaultStuSettings.pages, ...parsedValue.pages },
          groupLesson: { ...defaultStuSettings.groupLesson, ...parsedValue.groupLesson },
          appVersionData: { ...defaultStuSettings.appVersionData, ...parsedValue.appVersionData }
        };
        return reconstructedValue;
      }
      parsedValue = defaultStuSettings;
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error parsing studentSettings:', error);
    }
  }

  // Return the default valid values
  return parsedValue;
};

// this method will not always accept full lc_stusettings It can accept 
// partial values i-e sometime can accept *theme: {mode: 'light'}* etc.
// what it do, it get the existing saved object and update it with new
// values and persist the old vlaues.
export const setStuSettingsToLCStorage = (node: Partial<LcTchSettings>) => {
  const storedValues:LcTchSettings = getStuSettingsFromLCStorage();
  const updatedData = {
    ...storedValues,
    ...node,
  };
  localStorage.setItem(studentLocalStorageKeys.studentSettings, JSON.stringify(updatedData));
};

// #endregion lc_stusettings getter/setter helper functions.

// #region

/* NOTE: In TypeScript, the is keyword is used in type guard functions to narrow
         down the type of a value within a conditional block. 
*/

export const defaultGroupLesson: GroupLesson = {
  isMute: false,
  micId: null, // by default micId is nul.
};

export const defaultAppVersionData: AppVersionData = {
  hardRefreshCount: 0,
  timeStampInLC: null
};

// default lc_studettings obj.
// IMPORTANT: In future if we need to add/update lc_studettings then this is the 
// default object. We need to edit this.
const defaultStuSettings:LcTchSettings = {
  theme: { mode: 'light' },
  lang: { label: 'English', value: 'en' },
  navbar: {
    collapse: false,
    expandedItems: setDefaultSideNav()
  },
  pages: {
    findteacher: {
      lastVisited: null,
      tchorder: [],
      lang: 0, // defualt value for `All Langugae` is 0,
      tchscrolled: null
    }
  },
  groupLesson: defaultGroupLesson,
  appVersionData: defaultAppVersionData
};

/** navConfig object is responsible of displaying our site sideNav
 * we are persisting user actions on sideNav for example if user have some
 * items open inside sideNav and user visit our site after few days, we display
 * open items as open and closed one as close.
 * here we are saving each item id and isOpen flag in localStorage so once our page
 * load or reload, we compare our navConfig with this stored values and update it
 * with stored values.
 */
export function setDefaultSideNav() {
  const arr = navConfig.items.map((item) => (
    {
      id: item.id,
      isOpen: item.isOpen
    }
  ));
  return arr;
}

// The function checks if the parsed value is a valid partial LcTchSettings object. 
// It allows properties to be undefined, indicating partial objects are valid.
// NOTE: this function will return false if any value manipulated by dirty user.
const isValidPartialLcStuSettings = (obj: any): obj is Partial<LcTchSettings> => (
  obj
    && typeof obj === 'object'
    && (obj.navbar === undefined || isValidNavbar(obj.navbar))
    && (obj.theme === undefined || isValidTheme(obj.theme))
    && (obj.lang === undefined || isValidLang(obj.lang))
    && (obj.pages === undefined || isValidPage(obj.pages))
    && (obj.groupLesson === undefined || isValidGroupLesson(obj.groupLesson))
    && (obj.appVersionData === undefined || isValidVersionData(obj.appVersionData))
);

// Define type guard functions for nested objects
const isValidNavbar = (navbar: any): navbar is NAVBAR => (
  navbar
    && typeof navbar === 'object'
    && 'collapse' in navbar
    && typeof navbar.collapse === 'boolean'
    && 'expandedItems' in navbar
    && Array.isArray(navbar.expandedItems)
    && navbar.expandedItems.every(isValidExpandedItem)
);

const isValidExpandedItem = (item: any): item is NAVBAR['expandedItems'] => (
  item
    && typeof item === 'object'
    && 'id' in item
    && typeof item.id === 'number'
    && 'isOpen' in item
    && typeof item.isOpen === 'boolean'
);

const isValidTheme = (theme: any): theme is THEME => (
  theme
    && typeof theme === 'object'
    && 'mode' in theme
    && (theme.mode === 'light' || theme.mode === 'dark')
);

const isValidLang = (lang: any): lang is LANG => (
  lang
    && typeof lang === 'object'
    && 'label' in lang
    && typeof lang.label === 'string'
    && 'value' in lang
    && typeof lang.value === 'string'
);

const isValidPage = (pages: any): pages is PAGES => (
  pages
  && typeof pages === 'object'
  && 'findteacher' in pages
  && isValidFindTeacher(pages.findteacher)
);

const isValidFindTeacher = (findteacher: any): findteacher is FindTeacher => (
  findteacher
  && typeof findteacher === 'object'
  && 'lastVisited' in findteacher
  && (typeof findteacher.lastVisited === 'number' || findteacher.lastVisited === null)
  && 'tchorder' in findteacher
  && Array.isArray(findteacher.tchorder)
  && findteacher.tchorder.every(isValidTeacherOrder)
  && 'lang' in findteacher
  && (typeof findteacher.lang === 'number')
  && 'tchscrolled' in findteacher
  && (typeof findteacher.tchscrolled === 'string' || findteacher.tchscrolled === null)
);

const isValidTeacherOrder = (item: any): item is string => (
  item
    && typeof item === 'string'
);
// type checking for valid group lesson obj.
const isValidGroupLesson = (groupLesson: any): groupLesson is GroupLesson => (
  groupLesson
  && typeof groupLesson === 'object'
  && 'isMute' in groupLesson
  && (typeof groupLesson.isMute === 'boolean')
  && 'micId' in groupLesson
  && (typeof groupLesson.micId === 'string' || groupLesson.micId === null)
);

const isValidVersionData = (appVersionData: any): appVersionData is AppVersionData => (
  appVersionData
  && typeof appVersionData === 'object'
  && 'hardRefreshCount' in appVersionData
  && (typeof appVersionData.hardRefreshCount === 'number')
  && 'timeStampInLC' in appVersionData
  && (typeof appVersionData.timeStampInLC === 'number' || appVersionData.timeStampInLC === null)
);

// #endregion lc_stusettings getter/setter helper functions.

// #region usage functions

// updates the pages.findteacher node, which is used by the "discover a teacher" page
export const updateDiscoverTeacherNodeInLCSettings = (updatedKeyValues: Partial<FindTeacher>) => {
  const localData = getStuSettingsFromLCStorage();
  const existingPagesData = localData.pages.findteacher;
  const newSettings = {
    pages: {
      ...localData.pages,
      findteacher: {
        ...existingPagesData,
        ...updatedKeyValues
      }
    }
  };
  setStuSettingsToLCStorage(newSettings);
};

// #endregion
