import React, { useState } from 'react';
import {
  Grid2Ct, Grid2, CardStandard, Ty, IcSvgList, CardSmallColor, Link, Dialog, Ic,
} from '@languageconvo/wcl';
import { NavLink } from 'react-router-dom';
import { LayoutPosttrial } from '../../posttrial/LayoutPosttrial';
import { sitedata } from '../../../../../../utils/sitedata';

/* student has completed a trial lesson. Explain how they can schedule more or purchase
*/
export const TrialCompleted = () => {
  // #region modal

  // modal state. true=open, false=closed. closed by default
  const [modalState, setModalState] = useState<boolean>(false);

  // clicks on the element to open the modal
  const handleModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalState(true);
  };

  // modal close action
  const handleModalClose = () => {
    setModalState(false);
  };

  // #endregion

  return (
    <>
      {/* note about how to purchase or take more trials */}
      <Grid2Ct sx={{ mb: 1 }}>
        <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
          <CardStandard
            titleText="Next Steps!"
            titleIcon={IcSvgList.star1}
            color="accentBlue1"
            titleIconRight={0}
            cp={{ sx: { width: '100%' } }}
          >
            <Grid2Ct>
              {/* text */}
              <Grid2 xs={12}>
                <Ty>
                  We really hope you enjoyed meeting with your teacher. If you did, you can
                  purchase and continue with them!
                </Ty>
              </Grid2>

              {/* card color - purchase */}
              <Grid2 xs={12} xl={6}>
                <Link
                  to={sitedata.url.app.purchaseBuy}
                  component={NavLink}
                  linkStyle="nostyle"
                >
                  <CardSmallColor
                    text="Purchase"
                    icon={IcSvgList.purchase1}
                    color="accentGreen1"
                    hovercursor="pointer"
                  />
                </Link>
              </Grid2>

              {/* card color - try more */}
              <Grid2 xs={12} xl={6}>
                <CardSmallColor
                  text="Try More"
                  icon={IcSvgList.group1}
                  color="accentBlue1"
                  hovercursor="pointer"
                  onClick={handleModalOpen}
                />
              </Grid2>
            </Grid2Ct>
          </CardStandard>
        </Grid2>
        <Grid2 xs={12} md={6} sx={{ display: 'flex' }}>
          <ContactUs />
        </Grid2>
      </Grid2Ct>

      {/* normal display */}
      <LayoutPosttrial />

      <Dialog
        isOpen={modalState}
        onClose={handleModalClose}
        width="md"
        color="accentBlue1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty v="h2New"><Ic iconName="calendar-heart" iconStyle="duotone" color="accentGreen1" />&nbsp;&nbsp;Try New Teachers Any Time, Even Years From Now!</Ty>
            <Ty>
              If you enjoyed your trial lesson, great, continue with that teacher! At any time
              &ndash; tomorrow, next week, even years from now &ndash; you can try lessons
              with other teachers completely for  free. Just go to the &quot;Discover
              Teachers&quot; page to browse teacher profiles and schedule a lesson with anyone.
            </Ty>
            <br />
            <Ty v="h2New"><Ic iconName="question" iconStyle="duotone" color="accentPurple1" />&nbsp;&nbsp;Where is the Discover Teachers Page?</Ty>
            <Ty>
              You&apos;ll find a link to it on the main Lessons page of your account. Head there to
              see all of our teachers who are currently accepting new students!
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </>
  );
};

// #region subcomponents

const ContactUs = () => {
  // link to more page
  const moreUrl = sitedata.url.app.moreHome;
  const clickableTypeObjMore = {
    to: moreUrl,
    component: NavLink,
  };

  return (
    <CardStandard
      titleText="Contact Us"
      titleIcon={IcSvgList.customersupport1}
      color="accentBlue1"
      cp={{ sx: { width: '100%' } }} // needed when using display flex on parent
      clickableLink1={{ ...clickableTypeObjMore }}
    >
      <Link
        to={moreUrl}
        component={NavLink}
        linkStyle="nostyle"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty>
              Have questions?
            </Ty>
            <Ty>
              We have a customer support team that can help if you have any questions
              you would like to ask. Just head to the &quot;More&quot; page to chat
              with us anytime, we are real (friendly) people!
            </Ty>
          </Grid2>

          {/* card small color, link to contact us page. only shown on screens sizes
            where the "next steps" card height is much larger than this card  */}
          <Grid2 xs={12} sx={{ display: { xs: 'none', md: 'block', xl: 'none' } }}>
            <CardSmallColor
              text="Contact Us"
              icon={IcSvgList.mail1}
              color="accentBlue1"
              hovercursor="pointer"
            />
          </Grid2>
        </Grid2Ct>
      </Link>
    </CardStandard>
  );
};

// #endregion
