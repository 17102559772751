import React from 'react';
import { DateTime } from 'luxon';
import { useFragment } from 'react-relay';
import {
  SwiperSlide, Ty, Grid2Ct, Grid2, Button, Box,
} from '@languageconvo/wcl';
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from 'react-router-dom';
import { validateHourCycle } from '../../../../../../../common/utils/DatesTimes/HourCycle';
import {
  PrivateUpcomingDataUserFragment,
  PrivateUpcomingDataLessonsFragment,
} from '../../../../relay/posttrial/PrivateUpcomingData';
import { PrivateUpcomingDataLessonsFragment$key } from '../../../../relay/posttrial/__generated__/PrivateUpcomingDataLessonsFragment.graphql';
import { PrivateUpcomingDataUserFragment$key } from '../../../../relay/posttrial/__generated__/PrivateUpcomingDataUserFragment.graphql';
import { sitedata } from '../../../../../../../utils/sitedata';

interface Props {
  lesson: PrivateUpcomingDataLessonsFragment$key
  user: PrivateUpcomingDataUserFragment$key
}

// individual card for the lesson being shown
export const PrivateSchedLsnSliderCard = ({
  lesson,
  user,
}: Props) => {
  const lessonData = useFragment(PrivateUpcomingDataLessonsFragment, lesson);
  const userData = useFragment(PrivateUpcomingDataUserFragment, user);

  // lesson date
  const startDateTime = DateTime.fromSeconds(lessonData.starts_at);
  const endDateTime = DateTime.fromSeconds(lessonData.ends_at);
  const lsnStartDate = startDateTime.toLocaleString({
    day: 'numeric',
    weekday: 'long',
    month: 'short',
  });

  // lesson time range. as always, ensure hour_cycle is a valid value
  const hrCy = validateHourCycle(userData.hour_cycle);
  const timeOpts: Intl.DateTimeFormatOptions = {
    timeStyle: 'short',
    hourCycle: hrCy,
  };
  const lsnTimeRange = `${startDateTime.toLocaleString(timeOpts)} - ${endDateTime.toLocaleString(timeOpts)}`;

  // link to classroom
  const url = `${sitedata.url.app.privateClassroom}${lessonData.id_random}`;
  // TODO: the "to" here doesn't do anything. we needed target="_blank", and this was
  // the only easy way to get it. it's not correct though, something weird is going on.
  // https://mui.com/material-ui/guides/routing/#react-router-examples
  const LinkBehavior = React.forwardRef<any, Omit<RouterLinkProps, 'to'>>(
    (props, ref) => <RouterLink ref={ref} to={url} target="_blank" {...props} role={undefined} />,
  );

  return (
    <SwiperSlide>
      <Grid2Ct sx={{ mt: 2 }}>
        {/* date, time range, teacher name */}
        <Grid2 xs={12}>
          {/* noWrap is important. if any individual line is too long it will NOT wrap to
          another line. if it did, the "enter class" button might be pushed down out of view */}
          <Ty removeMb align="center" noWrap>
            {lsnStartDate}<br />
            {lsnTimeRange}<br />
            {lessonData.resources.title}
          </Ty>
        </Grid2>

        {/* button link to the classroom */}
        <Grid2 xs={12}>
          <Box display="flex" justifyContent="center">
            <Button
              color="accentOrange1"
              to={url}
              component={LinkBehavior}
            >
              Enter Class!
            </Button>
          </Box>
        </Grid2>
      </Grid2Ct>
    </SwiperSlide>
  );
};
