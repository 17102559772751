import {
  Box, WCLThemeProps, styled,
} from '@languageconvo/wcl';
import { HEADER, INNER_PADDING } from '../../../config';

/** Box (div) that takes the full height of *most* pages. Often userful for centering an element
 * vertically on a page. Must include a minimum height (theMinHeight) -- set this to something
 * larger than your main contents so that it never overflows vertically.
 * 
 * IMPORTANT: this does not take the exact full height of pages, it's close though
 * 
 * IMPORTANT: some pages, like the group classroom, do not have our standard layout so this does
 *  not work on them. The group classroom for example does not have a bottom bar on mobile
 */
export const AlmostFullInnerHeightMostPages = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'theMinHeight',
})(({ theme, theMinHeight }: WCLThemeProps & any) => ({
  minHeight: `${theMinHeight}px`,

  // xs, sm, md 
  // there is a top and bottom bar, so subtract both of those, and subtract the top and
  // bottom padding
  [theme!.breakpoints.down('lg')]: {
    height: `calc(100vh - ${HEADER.MOBILE_HEIGHT}px - ${HEADER.MOBILE_BOTTOMBAR_HEIGHT}px - ${INNER_PADDING.BOTTOM_PADDING}px - ${INNER_PADDING.TOP_PADDING_XS}px)`,
    // @ts-ignore
    height: `calc(100dvh - ${HEADER.MOBILE_HEIGHT}px - ${HEADER.MOBILE_BOTTOMBAR_HEIGHT}px - ${INNER_PADDING.BOTTOM_PADDING}px - ${INNER_PADDING.TOP_PADDING_XS}px)`, // eslint-disable-line
  },

  // lg, xl, xxl
  // simply subtrack the header height from 100% (there is no bottom bar on desktop screens)
  // and the top and bottom padding
  [theme!.breakpoints.up('lg')]: {
    height: `calc(100vh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px - ${INNER_PADDING.BOTTOM_PADDING}px - ${INNER_PADDING.TOP_PADDING_LG}px)`,
    // @ts-ignore
    height: `calc(100dvh - ${HEADER.DASHBOARD_DESKTOP_HEIGHT}px - ${INNER_PADDING.BOTTOM_PADDING}px - ${INNER_PADDING.TOP_PADDING_LG}px)`, // eslint-disable-line
  },
}));
