import React from 'react';
import { NavLink } from 'react-router-dom';
import IframeResizer from 'iframe-resizer-react';
import {
  Grid2, Grid2Ct, useTheme, Card, Link,
} from '@languageconvo/wcl';
import { CardContentNopadding } from '../../../common/components/card/Card';
import { NavtabCardContent, NavtabItemContainer, NavtabItemContainerXs } from '../../../common/components/navtabs/navtabs.style';
import { sitedata } from '../../../utils/sitedata';

export const LegacyTipteacher = () => {
  const theme = useTheme();
  const br = theme?.ourTheme.borders.borderRadius.medium;

  return (
    <Grid2Ct>
      {/* navtabs */}
      {/* xs
        very custom. smaller font sizes, smaller margins, etc.
      */}
      <Grid2 xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
        <Card>
          <NavtabCardContent>
            <Grid2 container spacing={1}>
              <Grid2 xs={4}>
                <Link to={sitedata.url.app.purchaseBuy} component={NavLink} linkStyle="nostyle"><NavtabItemContainerXs>Buy<br />Lessons</NavtabItemContainerXs></Link>
              </Grid2>
              <Grid2 xs={4}>
                <Link to={sitedata.url.app.purchaseTip} component={NavLink} linkStyle="nostyle"><NavtabItemContainerXs className="selected">Tip<br />Teacher</NavtabItemContainerXs></Link>
              </Grid2>
              <Grid2 xs={4}>
                <Link to={sitedata.url.app.purchaseHistory} component={NavLink} linkStyle="nostyle"><NavtabItemContainerXs>Purchase<br />History</NavtabItemContainerXs></Link>
              </Grid2>
            </Grid2>
          </NavtabCardContent>
        </Card>
      </Grid2>
      {/* sm
        text is two lines e.g. "Buy<br />Lessons"
        font size is the same though, no changes to it
      */}
      <Grid2 xs={12} xl={9} xxl={8} sx={{ display: { xs: 'none', sm: 'block', md: 'none' } }}>
        <Card>
          <NavtabCardContent>
            <Grid2 container spacing={1}>
              <Grid2 xs={12} sm={4}>
                <Link to={sitedata.url.app.purchaseBuy} component={NavLink} linkStyle="nostyle"><NavtabItemContainer>Buy<br />Lessons</NavtabItemContainer></Link>
              </Grid2>
              <Grid2 xs={12} sm={4}>
                <Link to={sitedata.url.app.purchaseTip} component={NavLink} linkStyle="nostyle"><NavtabItemContainer className="selected">Tip<br />Teacher</NavtabItemContainer></Link>
              </Grid2>
              <Grid2 xs={12} sm={4}>
                <Link to={sitedata.url.app.purchaseHistory} component={NavLink} linkStyle="nostyle"><NavtabItemContainer>Purchase<br />History</NavtabItemContainer></Link>
              </Grid2>
            </Grid2>
          </NavtabCardContent>
        </Card>
      </Grid2>
      {/* md, lg, xl, xxl
        text is flat, full width */}
      <Grid2 xs={12} xl={9} xxl={8} sx={{ display: { xs: 'none', md: 'block' } }}>
        <Card>
          <NavtabCardContent>
            <Grid2 container spacing={1}>
              <Grid2 xs={12} sm={4}>
                <Link to={sitedata.url.app.purchaseBuy} component={NavLink} linkStyle="nostyle"><NavtabItemContainer>Buy Lessons</NavtabItemContainer></Link>
              </Grid2>
              <Grid2 xs={12} sm={4}>
                <Link to={sitedata.url.app.purchaseTip} component={NavLink} linkStyle="nostyle"><NavtabItemContainer className="selected">Tip Teacher</NavtabItemContainer></Link>
              </Grid2>
              <Grid2 xs={12} sm={4}>
                <Link to={sitedata.url.app.purchaseHistory} component={NavLink} linkStyle="nostyle"><NavtabItemContainer>Purchase History</NavtabItemContainer></Link>
              </Grid2>
            </Grid2>
          </NavtabCardContent>
        </Card>
      </Grid2>

      {/* main content */}
      <Grid2 xs={12}>
        <Grid2Ct>
          <Grid2 xs={12}>
            <Card>
              <CardContentNopadding>
                <IframeResizer
                  id="tipTeacherFrame"
                  src={`${process.env.REACT_APP_LEGACYURL}/studenthome/?nts-panel=customer/purchase/tipteacher/`}
                  style={{
                    width: '1px',
                    minWidth: '100%',
                    minHeight: '90vh',
                    borderRadius: `${br}px`,
                    border: 'none',
                  }}
                />
              </CardContentNopadding>
            </Card>
          </Grid2>
        </Grid2Ct>
      </Grid2>
    </Grid2Ct>
  );
};
