import React from 'react';
import {
  Grid2, Grid2Ct, useTheme,
} from '@languageconvo/wcl';

// PayPalHostedFields component renders hosted fields for PayPal card payments.
// It includes fields for card number, CVV, and expiration date with custom styles and localization.
export const PayPalHostedFields = ({ PayPalHostedField }: any) => {
  const theme = useTheme();
  const bgColor = `hsla(${theme?.ourTheme.colors.app.page}, .6)`;

  // IMPORTANT #1: if you edit these styles, dont forget to edit the styles of the first and
  //  last name inputs. they are not "hosted fields" so do not get these styles
  const styleObj = {
    backgroundColor: bgColor,
    height: '53.13px',
    borderWidth: '1px',
    border: `1px solid ${theme.ourTheme.colors.cp.divider.thin}`,
    borderRadius: theme.ourTheme.borders.borderRadius.medium,
    paddingTop: '15px',
    paddingBottom: '15px',
    paddingLeft: '14px',
    paddingRight: '14px',
  };

  return (
    <Grid2Ct sx={{ mt: { xs: 0, sm: 2 } }}>
      {/* card number */}
      <Grid2 xs={12}>
        <PayPalHostedField
          id="card-number"
          className="card-field"
          hostedFieldType="number"
          style={{ ...styleObj }}
          options={{
            selector: '#card-number',
            placeholder: '4111-1111-1111-1111',
          }}
        />
      </Grid2>

      {/* cvv */}
      <Grid2 xs={6}>
        <PayPalHostedField
          id="cvv"
          className="card-field"
          hostedFieldType="cvv"
          style={{ ...styleObj }}
          options={{
            selector: '#cvv',
            placeholder: 'CVV',
          }}
        />
      </Grid2>

      {/* expiration date */}
      <Grid2 xs={6}>
        <PayPalHostedField
          id="expiration-date"
          className="card-field"
          hostedFieldType="expirationDate"
          style={{ ...styleObj }}
          options={{
            selector: '#expiration-date',
            placeholder: 'MM/YY',
          }}
        />
      </Grid2>
    </Grid2Ct>
  );
};
