import { graphql } from 'babel-plugin-relay/macro';

export const PageloadQuery = graphql`
  query PageloadQuery {
    users_connection {
      edges {
        node {
          ...PageloadLanglearnFragment
          ...PageloadTimesettingsFragment
        }
      }
    }
  }
`;

// language the user is learning. needed for the subscription to run
export const PageloadLanglearnFragment = graphql`
  fragment PageloadLanglearnFragment on users {
    lang_learning
  }
`;

// hour_cycle needed to display lesson times correctly to the user
export const PageloadTimesettingsFragment = graphql`
  fragment PageloadTimesettingsFragment on users {
    hour_cycle
  }
`;
