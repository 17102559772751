import React, {
  forwardRef, useEffect, Dispatch, SetStateAction, ReactNode, useLayoutEffect,
} from 'react';
import { commitLocalUpdate } from 'react-relay';
import { Box, Container } from '@languageconvo/wcl';
import { Helmet } from 'react-helmet-async';
import { useLocation, useOutletContext } from 'react-router-dom';
import scrollIntoView from 'scroll-into-view-if-needed';
import AppEnvironment from '../../../relay/AppEnvironment';
import { RelayAppSettingsVals } from '../../relay/clientschema/RelayAppSettingsTypes';
import { INNER_PADDING } from '../../../config';

type OutLetContext = {
  setNoScroll: Dispatch<SetStateAction<boolean | undefined>>
}

interface Props {
  children: ReactNode;
  meta?: ReactNode;
  title: string;
  dataTestId: string;
  // determines whether or not we show the back arrow in the app bar. if null, we do not.
  // if a string, we show the arrow, with a link to whatever the string value is set to
  backButton: string | null,
  noScroll?: boolean;
  maxWidth?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'pageContainer',
  noDefaultScroll?: boolean
}

export const Page = forwardRef<HTMLDivElement, Props>(({
  children,
  title = '',
  meta,
  maxWidth = 'pageContainer',
  noScroll,
  dataTestId,
  backButton,
  noDefaultScroll = true,
  ...other
}, ref) => {
  const location = useLocation();

  // change scroll state in the layout parent component.
  // when true hides the scrollbar.
  const outletContext = useOutletContext<OutLetContext>();
  useEffect(() => {
    // outletContext will not exist in the follwoing layouts: authLayout & logoOnly as
    // we don't need scroll there. but all our pages inside dashboardLayout must have scroll
    if (!outletContext) return;
    outletContext.setNoScroll(noScroll);
    // eslint-disable-next-line
  }, []);

  /* noDefaultScroll: React application have some weird scrolling behaviour, which is explained
    below Due to single page (SPA)
    To change the content, we chnage the routes and page does not refresh, in this case browser
    things I am on the same page and here the issue arises. Let's say, we have 1 route, where 
    content is larger, so, we need to scroll on this page to see last available content of this
    route. Till now, everything is ok, but one we change route and go to 2nd route, this route 
    should not show any already scrolled and it should show content from top of the page but this
    is not happening, we can see the scroll in this 2nd page too. which is really wiered. To
    prevent this behaviour, we are using noDefaultScroll flag, and if it is true, we always
    show page top level content instead of some intermediatery content of the page.

    This code block preventing page default scrolling and always show content from top.
   */
  useEffect(() => {
    if (noDefaultScroll) {
      const cardToScrollTo = document.querySelector('[data-top="top"]');
      if (cardToScrollTo) {
        // Scroll to the element
        scrollIntoView(cardToScrollTo!, {
          behavior: 'auto',
          scrollMode: 'if-needed',
          block: 'start',
        });
      }
    }
  }, [location, noDefaultScroll]);

  /* set RelayAppSettings.backbutton in the relay store. this causes the back arrow in the
      top app bar to be shown, or not.
      to avoid UI Flickering, we are using here the layoutEffect because it gets called
      before the component gets rendered on the UI.
  */
  useLayoutEffect(() => {
    commitLocalUpdate(AppEnvironment, (store) => {
      const root = store.getRoot();
      const record = store.get(RelayAppSettingsVals.idVal);
      if (record) {
        record.setValue(backButton, RelayAppSettingsVals.backbuttonName);
        root.setLinkedRecord(record, RelayAppSettingsVals.name);
      }
    });
  // this "location" dependency will make sure that useLayoutEffect will run on every page change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  const bottomPadding = `${INNER_PADDING.BOTTOM_PADDING}px`;

  return (
    <>
      <Helmet>
        <title>{title}</title>
        {meta}
      </Helmet>
      {/* padding bottom: we want this to be very small. otherwise there will be a vertical
        scroll when there doesn't need to be (a large bottom padding extends the height 
        of the page). but we don't want it to be 0, bc then the last card on the page
        is at the very bottom edge of the page */}

      <Container maxWidth={maxWidth} cp={{ 'data-testid': dataTestId, sx: { pb: bottomPadding } }}>
        <Box ref={ref} {...other}>
          {children}
        </Box>
      </Container>
    </>
  );
});
