import React, { useState } from 'react';
import {
  CardStandard, Ty, Grid2Ct, Grid2, Dialog, IcSvgList,
} from '@languageconvo/wcl';
import { GridEl } from './Common';
import { ImageNotReady } from './GroupLessons.style';

export const GroupLessons = () => {
  // #region modal

  // modal state. true=open, false=closed. closed by default
  const [modalState, setModalState] = useState<boolean>(false);

  // clicks on the element to open the modal
  const handleModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalState(true);
  };

  // modal close action
  const handleModalClose = () => {
    setModalState(false);
  };

  // #endregion

  return (
    <>
      {/* main component */}
      <GridEl>
        <CardStandard
          titleText="Group Lessons"
          titleIcon={IcSvgList.group1}
          color="accentBlue1"
          cp={{ sx: { width: '100%' } }}
          titleIconRight={0}
          onClickEntireCard={handleModalOpen}
          hovercursor="pointer"
        >
          <Grid2Ct>
            <Grid2 xs={12}>
              <Ty removeMb>Group lessons are in &quot;beta,&quot; meaning they&apos;re
                in the final testing phases. Check back soon and watch your email for announcements!
              </Ty>
            </Grid2>
          </Grid2Ct>
        </CardStandard>
      </GridEl>

      {/* modal */}
      <Dialog
        isOpen={modalState}
        onClose={handleModalClose}
        width="md"
        color="accentBlue1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty>
              We are in the final testing phase of a new, fun way to practice
              your language skills. Join a teacher and a small group of students
              for guided lessons that involve a learning and conversational component.
              The best part is the price — $30/month for unlimited lessons!
            </Ty>
          </Grid2>
        </Grid2Ct>

        <Grid2Ct sx={{ mt: 3, mb: 3 }}>
          <Grid2 xs={10} xsOffset={1} xxl={6} xxlOffset={3}>
            <ImageNotReady
              src={`${process.env.REACT_APP_IMAGESCDNURL}/uis/pages/grouplessons/group_jungle.png`}
              alt="Not Ready Yet"
            />
          </Grid2>
        </Grid2Ct>
      </Dialog>

    </>
  );
};
