import React, {
  createContext, Dispatch, SetStateAction, useEffect, useRef, useState,
} from 'react';
import * as Sentry from '@sentry/react';
import { useMutation } from 'react-relay';
import {
  ErrorsCapturePaymentMutation,
  ErrorsVerifyActivateMutation,
} from '../components/helper/Errors';
import { CapturePaymentMutation } from '../relay/mutations/CapturePaymentMutation';
import { VerifyActivateMutation } from '../relay/mutations/VerifyActivateMutation';

interface CapturePaymentContextProps {
  paymentSuccess: boolean;
  fixableErrors: boolean;
  nonFixableErrors: boolean;
  errCode: string | null;
  commitCaptureMutation: () => void;
  setIsLoading: Dispatch<SetStateAction<boolean>>;
  isLoading: boolean;
  setFixableErrors: Dispatch<SetStateAction<boolean>>;
  setErrCode: Dispatch<SetStateAction<string | null>>;
  orderInvoiceNumber: string | null;
  setOrderInvoiceNumber: Dispatch<SetStateAction<string | null>>;
  setNonFixableErrors: Dispatch<SetStateAction<boolean>>;
}

// the data.status field that's returned by our stu_orders_verifyactivate api call. see
// postman for exact details
enum VerifyactivateStatus {
  Pending = 'Pending',
  Completed = 'Completed',
  Failed = 'Failed'
}

const CapturePaymentContext = createContext<CapturePaymentContextProps>({
  paymentSuccess: false,
  fixableErrors: false,
  nonFixableErrors: false,
  errCode: null,
  orderInvoiceNumber: null,
  setOrderInvoiceNumber: () => {},
  commitCaptureMutation: () => {},
  setIsLoading: () => {},
  isLoading: false,
  setFixableErrors: () => {},
  setErrCode: () => {},
  setNonFixableErrors: () => {}
});

interface CapturePaymentProviderProps {
  children?: React.ReactNode;
}
const CapturePaymentProvider: React.FC<CapturePaymentProviderProps> = ({ children }) => {
  // #region general

  // state to disable the place order button
  const [isLoading, setIsLoading] = useState(false);
  // state to track payment status
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  // errors which user can fix
  const [fixableErrors, setFixableErrors] = useState(false);
  // error state for mutation failure
  const [nonFixableErrors, setNonFixableErrors] = useState(false);
  // state to show which type of error is occur
  const [errCode, setErrCode] = useState<string | null>(null);
  // state to store the invoice number
  const [orderInvoiceNumber, setOrderInvoiceNumber] = useState<string | null>(null);
  // we have created a reference here because we need the orderInvoiceNumber to make capture payment
  // and verify activate mutation call, but the issue here was that we cannot directly access the
  // updated value of orderInvoiceNumber. so, to get the updated value we are using this ref.
  const orderInvoiceNumberRef = useRef(orderInvoiceNumber);

  // this useEffect will trigger whenever there is a change in orderInvoiceNumber state,
  // once the create order api call successfully completed we will get the invoiceNumber
  // from backend and set it's value in setOrderInvoiceNumber (this is written in onCompleted method
  // of createOrderMutation in PurchasePayPage1), we have to set this value in
  // orderInvoiceNumberRef.current so we can get the update value in commitCaptureMutation and 
  // commitVerifyActivate
  useEffect(() => {
    orderInvoiceNumberRef.current = orderInvoiceNumber;
  }, [orderInvoiceNumber]);

  // #endregion

  // #region capture payment mutation

  // this mutation call will excute 1st when user click on place order button
  // or complete purchase button
  const [commitMutation] = useMutation(CapturePaymentMutation);

  // TODO: increase the timeout for this api call to 10 seconds, because processing cards
  //  can sometimes take longer than a few seconds
  // here we are executing our `CapturePaymentMutation` which attempts to actually charge the
  // user's card or paypal account
  const commitCaptureMutation = () => {
    commitMutation({
      variables: {
        invoiceNumber: orderInvoiceNumberRef.current,
      },

      // if capture payment was successful, we'll show the user a success message immediately
      // because they *should* have been charged. we'll call verify/activate api in the
      // background; that *should* activate the order so that the user can schedule lessons!
      onCompleted() {
        // eslint-disable-next-line no-console
        console.log('capture payment successful. attempting to activate purchase...');
        setPaymentSuccess(true);
        setIsLoading(false);
        handleVerifyActivate();
      },

      /** if there's an error with capture pay, we can't always know for sure if the user's card or
       * paypal account was charged. we have a few situations here:
       *  1. capture payment returned a known error. in this case, we can display an error to the
       *    user telling them they were *not* charged. we know for certain they were not charged
       *  2. capture payment returned an unknown error, timeout, etc. in this case we do NOT
       *    know for sure whether or not the user was charged. we will try to verify/activate this 
       *    order and if that succeeds, we know the user was charged and can be shown a success
       *    messages. 
       */
      onError(err: any) {
        // attempt to get the error code from our backend. this will only work if the error is a
        // known, expected error. thus the try/catch
        try {
          // eslint-disable-next-line no-console
          console.log('a capture payment error occurred', err);

          const errorObject = JSON.parse(err.message);
          const errorCode = errorObject?.extensions?.code;

          // a known error that is "fixable", meaning that the user can just click the buy button
          // again and we may be able to process their payment
          if (
            errorCode === ErrorsCapturePaymentMutation.Fixable_CARD_EXPIRED
            || errorCode === ErrorsCapturePaymentMutation.Fixable_INSTRUMENT_DECLINED
            || errorCode === ErrorsCapturePaymentMutation.Fixable_CARD_CLOSED
            || errorCode === ErrorsCapturePaymentMutation
              .Fixable_REDIRECT_PAYER_FOR_ALTERNATE_FUNDING
            || errorCode === ErrorsCapturePaymentMutation.Fixable_CARD_CVC_INVALID
            || errorCode === ErrorsCapturePaymentMutation.Fixable_PAYER_ACCOUNT_LOCKED_OR_CLOSED
            || errorCode === ErrorsCapturePaymentMutation.Fixable_PAYER_ACCOUNT_RESTRICTED
            || errorCode === ErrorsCapturePaymentMutation.Fixable_GENERAL_CARD_ERROR
            || errorCode === ErrorsCapturePaymentMutation.FixableGeneralError
            || errorCode === ErrorsCapturePaymentMutation.Fixable_ORDER_NOT_APPROVED
          ) {
            // all of the above are fixable errors. so, we're making the fixable errors state true.
            setFixableErrors(true);
            // we also has to know which error has occurred to show a specific message to user,
            // in this state variable we will set the error code that backend has returned.
            setErrCode(errorCode);
            setIsLoading(false);

            // a known error that is *not* fixable, meaning that the user needs to go back to the
            // main purchase page and select a package again
          } else if (
            errorCode === ErrorsCapturePaymentMutation.NonFixablePaypalPaymentNotFound
            || errorCode === ErrorsCapturePaymentMutation.NonFixable_RESOURCE_NOT_FOUND
          ) {
            // logging this error in sentry, because this should never occur 
            Sentry.captureException(
              new Error('capture payment mutation gave error, either the PaypalPaymentNotFound or RESOURCE_NOT_FOUND'),
              {
                extra: {
                  theErr: err
                }
              }
            );
            // these are the errors user cannot fix by them self so we have to throw user to
            // page level error
            setNonFixableErrors(true);
            setErrCode(errorCode);
            setIsLoading(false);

            // an unexpected error response. in this case we're not sure if the user purchased or
            // not so we call verify/activate to try to determine that
          } else {
            Sentry.captureException(
              new Error('BIG PROBLEM: capture payment mutation gave some unexpected error response, the code should never come here'),
              {
                extra: {
                  theErr: err
                }
              }
            );
            handleVerifyActivate();
          }

          // this will likely occur if the json.parse above fails (because the error we received
          // was not a known/expected error from our backend). in this case we are *not* sure
          // if the user was charged, so we call verify/activate to check and see if they were
          // charged
        } catch (e) {
          Sentry.captureException(e);
          Sentry.captureMessage('BIG PROBLEM: capture payment mutation gave some unexpected error response. See previous error');
          handleVerifyActivate();
        }
      },
    });
  };

  // #endregion

  // #region verify activate mutation

  // here we are configuring our `VerifyActivateMutation`, because once the payment is captured
  // we have to make this call to activate the user's lessons
  const [commitVerifyActivate] = useMutation(VerifyActivateMutation);

  // this api call attempts to activate the order, which will allow the user to start scheduling
  // lessons in our app right away
  const handleVerifyActivate = () => {
    commitVerifyActivate({
      variables: {
        invoiceNumber: orderInvoiceNumberRef.current,
      },

      onCompleted(dt: any) {
        // eslint-disable-next-line no-console
        console.log('verifyactivate successful. the data:', dt);

        // if the capture payment api call was successful, we have already displayed a success
        // message to the user by setting paymentSuccess = true, so we don't need to display
        // anything here. but if capture payment failed, paymentSuccess will not be true yet;
        // we need to analyze the results of this verifyactivate api call and display something
        // to the user
        if (!paymentSuccess) {
          const verifStatus = dt?.stu_orders_verifyactivate?.data?.status;

          // the purchase was "captured", so we can show a success message
          if (verifStatus === VerifyactivateStatus.Completed
            || verifStatus === VerifyactivateStatus.Pending
          ) {
            setPaymentSuccess(true);
            setIsLoading(false);

            // capturing the payment failed. the user needs to try to purchase again
          } else if (verifStatus === VerifyactivateStatus.Failed) {
            setFixableErrors(true);
            setErrCode(ErrorsVerifyActivateMutation.FixablePaymentfailed);

            // an unexpected response. should never happen! we need to tell the user we are not sure
            // if their purchase was completed or not, they MIGHT have been charged
          } else {
            Sentry.captureException(
              new Error('Important! verifyactivate returned success, but the response was unexpected'),
              {
                extra: {
                  theDt: dt
                }
              }
            );
            setNonFixableErrors(true);
            setErrCode(ErrorsVerifyActivateMutation.NonFixableUnsureOfPaymentStatus);
          }
        }
      },

      // if an error occurs with verify/activate
      onError(err: any) {
        // eslint-disable-next-line no-console
        console.log('verifyactivate error. the error:', err);

        // if capture order api call was successful, we do not "care" if verifyactivate failed. we 
        // have a cron job that runs periodically that will activate the order for the user. we
        // set paymentSuccess to true in this case, and showed the user a success message. if
        // capture order failed though (paymentSuccess is not true yet) we do need display
        // something to the user depending on what the results of this api call showed
        if (!paymentSuccess) {
          try {
            const errorObject = JSON.parse(err.message);
            const errorCode = errorObject?.extensions?.code;

            // known, unfixable errors
            if (errorCode === ErrorsVerifyActivateMutation.NonFixableOrderNotFound
              || errorCode === ErrorsVerifyActivateMutation.NonFixableUnsureOfPaymentStatus
            ) {
              setNonFixableErrors(true);
              setErrCode(errorCode);
              setIsLoading(false);

              // unexpected. we can't be sure if the user's purchase was captured. we need
              // to tell them we are unsure of whether or not they paid
            } else {
              Sentry.captureException(
                new Error('BIG PROBLEM: activate order mutation gave some unexpected error response'),
                {
                  extra: {
                    theErr: err
                  }
                }
              );
              setNonFixableErrors(true);
              setErrCode(ErrorsVerifyActivateMutation.NonFixableUnsureOfPaymentStatus);
              setIsLoading(false);
            }

            // if the above errors, we can't be sure if the user's purchase was captured. we need
            // to tell them we are unsure of whether or not they paid
          } catch (e) {
            setNonFixableErrors(true);
            setErrCode(ErrorsVerifyActivateMutation.NonFixableUnsureOfPaymentStatus);
            setIsLoading(false);
          }
        }
      },
    });
  };

  // #endregion

  const value = React.useMemo(
    () => ({
      paymentSuccess,
      fixableErrors,
      nonFixableErrors,
      errCode,
      commitCaptureMutation,
      setIsLoading,
      isLoading,
      setFixableErrors,
      setErrCode,
      orderInvoiceNumber,
      setOrderInvoiceNumber,
      setNonFixableErrors
    }),
    // eslint-disable-next-line
    [
      paymentSuccess, fixableErrors, nonFixableErrors, errCode,
      setIsLoading, isLoading, setErrCode, orderInvoiceNumber
    ]
  );
  return <CapturePaymentContext.Provider value={value}>{children}</CapturePaymentContext.Provider>;
};

export { CapturePaymentProvider, CapturePaymentContext };
