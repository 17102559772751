import React from 'react';
import {
  Grid2Ct, Grid2, Card, CardContent, IcSvg, IcSvgList, Ty, Button, RadioGroup, Box,
} from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-relay';
import { toast } from 'react-toastify';
import { Step1ValidationFields } from '../../schema/StepsToScheduleStep1';
import { StuSettingsUpdatetrialstep1Mutation } from '../../../../relay/posttrial/StuSettingsUpdatetrialstep1';
import {
  OptionsContainers,
  OptionsContainers2,
  OptionsContainers3
} from './StepsToSchedule4Step1.style';

interface Props {
  userSetId : string | undefined
}

// set whether you want private or group lessons
export const StepsToSchedule4Step1 = ({ userSetId }: Props) => {
  const options = [
    { label: 'Only 1-on-1 Lessons', value: 1 },
    { label: 'Only Group Lessons', value: 2 },
    { label: 'Both sound interesting!', value: 3 },
  ];

  // we have used react-hook-form here so we don't have to manually create the state and manage
  // that state, we pass in the default schema for validations and default values data we want
  // to render on the screen
  const {
    control, handleSubmit, formState: { errors }
  } = useForm <any>({
    reValidateMode: 'onChange',
    resolver: yupResolver(Step1ValidationFields),
  });

  // #region basic settings mutation

  // mutation call for the updation of user's interest
  const [UserInterestMutation, isInFlight] = useMutation(StuSettingsUpdatetrialstep1Mutation);

  // this function gets triggered when user click on Continue to Step 2 button,
  //  it initiates the mutation call
  const handleUserInterest = (data: any) => {
    const interestMapping: any = {
      1: { interestPrivateValue: 1, interestGroupValue: 0 }, // user interested in private lesson
      2: { interestPrivateValue: 0, interestGroupValue: 1 }, // user interested in group lesson
      3: { interestPrivateValue: 1, interestGroupValue: 1 }, // user interested in both
    };

    const { interestPrivateValue, interestGroupValue } = interestMapping[data.user_interest]
    || {};
    UserInterestMutation({
      variables: {
        interestPrivate: interestPrivateValue,
        interestGroup: interestGroupValue
      },
      // this creates our optimistic update. we provide the the data we
      // *expect* to be returned by the mutation. whether the mutation succeeds or
      // fails this gets overwritten once the response is received. note that the relay
      // docs say using optimisticResponse is usually better/easier than using
      // optimisticUpdater to accomplish an optimistic update
      optimisticResponse: {
        stu_settings_updatetrialstep1: {
          success: true,
          errors: [],
          data: {
            user_settings: {
              id: userSetId,
              interest_private: interestPrivateValue,
              interest_group: interestGroupValue,
            }
          }
        }
      },
      onCompleted() {
        // success, don't need to do anything
      },
      onError(err: any) {
        Sentry.captureException(err);
        toast.error('Hmm, something went wrong, please try that again.');
      }
    });
  };

  // #endregion

  return (
    <Grid2Ct>
      <Grid2 xs={12} sm={10} smOffset={1} md={8} mdOffset={2}>
        <Card>
          <CardContent>
            <Grid2Ct>
              {/* title and subtitle */}
              <Grid2 xs={12} display="flex" alignContent="center" justifyContent="center">
                <IcSvg width="40px" height="40px" icon={IcSvgList.teacher1} />&nbsp;&nbsp;&nbsp;&nbsp;<Ty v="h1New" removeMb>Step 1 of 3</Ty>
              </Grid2>
              <Grid2 xs={12}>
                <Box display="flex" justifyContent="center">
                  <Ty v="h2New">Schedule a Free Trial Lesson!</Ty>
                </Box>
              </Grid2>

              <Grid2 xs={12}>
                <Grid2Ct>
                  {/* 1-on-1 */}
                  <Grid2
                    xs={12}
                    xl={6}
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <OptionsContainers>
                      <Grid2Ct>
                        <Grid2 xs={12}>
                          <Ty v="subheading" align="center">
                            Private 1-on-1 Lessons
                          </Ty>
                          <Ty align="center" removeMb>
                            You and your long-term teacher meet 1-on-1. About $10 per 30
                            minutes ($20 per hour!)
                          </Ty>
                        </Grid2>
                      </Grid2Ct>
                    </OptionsContainers>
                  </Grid2>

                  {/* group */}
                  <Grid2
                    xs={12}
                    xl={6}
                    sx={{
                      display: 'flex',
                    }}
                  >
                    <OptionsContainers>
                      <Grid2Ct>
                        <Grid2 xs={12}>
                          <Ty v="subheading" align="center">
                            Small Group Lessons
                          </Ty>
                          <Ty align="center" removeMb>
                            You, a teacher, and a few other students. Attend
                            &nbsp;<em>unlimited</em>&nbsp;
                            group lessons for just $30/month!
                          </Ty>
                        </Grid2>
                      </Grid2Ct>
                    </OptionsContainers>
                  </Grid2>
                </Grid2Ct>
              </Grid2>

              {/* which interested? */}
              <Grid2 xs={12} xl={6} xlOffset={3} sx={{ mt: 1 }}>
                <OptionsContainers2>
                  <Box display="flex" justifyContent="center">
                    <Ty v="subheading" align="center" removeMb>
                      Which are you interested in?
                    </Ty>
                  </Box>

                  <Box display="flex" justifyContent="center">
                    <Controller
                      name="user_interest"
                      control={control}
                      render={
                    ({ field: { onChange, value } }) => (
                      <RadioGroup
                        options={options}
                        value={value}
                        onChange={onChange}
                        defaultValue={options[0]}
                      />
                    )
                  }
                    />
                  </Box>
                </OptionsContainers2>
              </Grid2>
              {/* alert forgot to set option in radio group */}
              {Object.keys(errors).length > 0 && (
              <Grid2 xs={12} xl={6} xlOffset={3}>
                <OptionsContainers3>
                  <Box display="flex" justifyContent="center">
                    <Ty align="center" removeMb>
                      Oops! It looks like you forgot to select an option above.
                    </Ty>
                  </Box>
                </OptionsContainers3>
              </Grid2>
              )}
              {/* next step button */}
              <Grid2 xs={12}>
                <Box display="flex" justifyContent="center">
                  <Button
                    color="accentPurple1"
                    size="large"
                    disabled={isInFlight}
                    onClick={handleSubmit(handleUserInterest)}
                  >Continue to Step 2
                  </Button>
                </Box>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2Ct>
  );
};
