import { graphql } from 'babel-plugin-relay/macro';

export const GeneratePayPalToken = graphql`
mutation GeneratePayPalTokenMutation {
  orders_generatepaypalclienttoken {
    data {
      clientToken
    }
    success
  }
}`;
