import React, { useState, Suspense } from 'react';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import {
  Card, CardContent, Ty, Grid2Ct, Grid2, IcSvgList, IcSvg, Box, styled, WCLThemeProps,
  Dialog,
} from '@languageconvo/wcl';
import { AlignIconText } from '../../../common/components/styling/AlignIconText';
import { SkillsQuery, SkillsAllFragment, SkillsDetailFragment } from './relay/Skills';
import { SkillsAllFragment$key } from './relay/__generated__/SkillsAllFragment.graphql';
import { SkillsDetailFragment$key } from './relay/__generated__/SkillsDetailFragment.graphql';

export const ProgressHome = () => {
  const response: any = useLazyLoadQuery(
    SkillsQuery,
    {}
  );
  const fragmentRef: SkillsAllFragment$key = response;
  const data = useFragment(SkillsAllFragment, fragmentRef);
  const allLevels = data.skills_levels_connection;

  // #region individual skill dialog

  // the modal/open close state
  const [modalState, setModalState] = useState<boolean>(false);
  // the individual skill that was clicked on (display_order field from the db)
  const [skillClicked, setSkillClicked] = useState<number>(0);

  const handleModalClick = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    // get the id of the skill that was clicked on, and set it to our state var
    const skillId = parseInt(event.currentTarget.getAttribute('data-skillid'), 10);
    setSkillClicked(skillId);
    setModalState(true);
  };
  const handleModalClose = () => {
    setModalState(false);
  };

  // #endregion

  return (
    <>
      {/* TODO: design loading */}
      <Suspense fallback={<span>loading...</span>}>
        <Grid2Ct>
          { allLevels.edges.map(({ node }: any) => (
            <LevelCard fragmentRef={node} handleModalClick={handleModalClick} />
          ))}
        </Grid2Ct>
      </Suspense>

      <Dialog
        isOpen={modalState}
        onClose={handleModalClose}
        width="md"
        color="accentGreen1"
      >
        <DialogContentsContainer skill={skillClicked} allLevels={allLevels} />
      </Dialog>
    </>
  );
};

// #region components

// #region card for an entire level

interface Props {
  fragmentRef: SkillsDetailFragment$key
  handleModalClick: any
}
const LevelCard = ({ fragmentRef, handleModalClick }: Props) => {
  const levelData = useFragment(SkillsDetailFragment, fragmentRef);

  return (
    <Grid2 xs={12} xl={10} xlOffset={1}>
      <Card>
        <CardContent>
          <Grid2Ct sx={{ mt: 1 }}>
            <Grid2 xs={12}>
              {/* level title, icon */}
              <Box display="flex" justifyContent="center">
                <AlignIconText>
                  <IcSvg icon={IcSvgList.star1} width="24px" height="24px" />&nbsp;&nbsp;&nbsp;
                  <Ty align="center" v="h2New" removeMb>{levelData.title_en}</Ty>
                </AlignIconText>
              </Box>

              {/* each skill */}
              <Grid2Ct sx={{ mt: 3 }}>
                {levelData.skills.map((el) => (
                  <IndividualSkill skill={el} handleModalClick={handleModalClick} />
                ))}
              </Grid2Ct>
            </Grid2>
          </Grid2Ct>
        </CardContent>
      </Card>
    </Grid2>
  );
};

// #endregion

// #region individual skill box

const IndividualSkill = ({ skill, handleModalClick }: any) => {
  const skillId = skill.display_order;
  const header = `Skill #${skillId}`;
  const skillName = `${skill.title_en}`;

  return (
    <Grid2 xs={12} md={6}>
      <Box display="flex" justifyContent="center">
        <IndividualSkillBox
          onClick={(event) => handleModalClick(event)}
          data-skillid={skillId}
        >
          <Ty align="center">{header}</Ty>
          <Ty align="center" removeMb>{skillName}</Ty>
        </IndividualSkillBox>
      </Box>
    </Grid2>
  );
};

const IndividualSkillBox = styled(Box)<WCLThemeProps>(({ theme }) => ({
  backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentGreen1}`,
  borderRadius: `${theme.ourTheme.borders.borderRadius.medium}px`,
  padding: '15px 15px 15px 15px',
  cursor: 'pointer',
  width: '400px',
}));

// #endregion

// #region dialog contents

// as a first step for the dialog contents, we map through each level
const DialogContentsContainer = ({ skill, allLevels }: any) => {
  // eslint-disable-next-line
  const x = 1;

  return (
    allLevels.edges.map(({ node }: any) => (
      <DialogContents fragmentRef={node} selectedSkill={skill} />
    ))
  );
};

// here we have to loop through each skill, find the skill that the user clicked on,
// and then we can build the display/output
const DialogContents = ({ fragmentRef, selectedSkill }: any) => {
  const levelData = useFragment(SkillsDetailFragment, fragmentRef);
  let foundSkill = false;
  let fullTitle = '';
  let description = '';
  let examples: string[] = ['one'];
  levelData.skills.forEach((el: any) => {
    if (el.display_order === selectedSkill) {
      foundSkill = true;
      fullTitle = `Skill #${el.display_order}: ${el.title_en}`;
      description = el.description_en;
      examples = JSON.parse(el.examples_en);
    }
  });

  if (!foundSkill) {
    return (null);
  }

  return (
    <Grid2Ct>
      <Grid2 xs={12}>
        <Ty v="h2New" align="center">{fullTitle}</Ty>
      </Grid2>
      <Grid2 xs={12}>
        <Ty>{description}</Ty>
      </Grid2>
      <Grid2 xs={12}>
        <Ty><strong>Examples</strong></Ty>
        {examples.map((el) => (
          <Ty>{el}</Ty>
        ))}
      </Grid2>
    </Grid2Ct>
  );
};

// #endregion

// #endregion
