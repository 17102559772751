import React from 'react';
import { Alert } from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import {
  ErrorsCapturePaymentMutation,
  ErrorsClickingOnSubmitButton,
  ErrorsCreateOrderMutation,
  ErrorsVerifyActivateMutation,
} from './Errors';

// errors that the user can potentially fix and re-run their payment, by clicking the buy
// button again
export const FixableError = ({ errCode }: any) => {
  // eslint-disable-next-line no-console
  console.log('FixableErrorAlertComponent error:', errCode);
  let errString = 'Hmm, something went wrong. Please go back to the main Purchase page and check your purchase history to ensure that you were not charged (you will see a purchase listed there if you were charged). If you continue to see this error PLEASE let us know, it should rarely if ever occur.';

  // create order api errors
  if (errCode === ErrorsCreateOrderMutation.FixableExternalAPIFailure
    || errCode === ErrorsCreateOrderMutation.FixableFailedToCreateInactiveOrder
    || errCode === ErrorsCreateOrderMutation.FixablePriceNotVerified
    || errCode === ErrorsCreateOrderMutation.FixableFailedToUpdatePaymentId
    || errCode === ErrorsCreateOrderMutation.FixableNoPaymentId
    || errCode === ErrorsCreateOrderMutation.FixableUnknown
  ) {
    errString = 'Oops, it looks like our payment processor had a temporary issue. Please try clicking the button again.';

    // capture payment api errors
  } else if (errCode === ErrorsCapturePaymentMutation.Fixable_CARD_EXPIRED) {
    errString = 'It looks like the card you attempted to use may have expired. Please check that and try a different card if that is the case.';
  } else if (errCode === ErrorsCapturePaymentMutation.Fixable_INSTRUMENT_DECLINED) {
    errString = 'It looks like your bank declined the payment. Please try again; if you continue to see this you may need to contact your back to allow the charge, or try a different card.';
  } else if (errCode === ErrorsCapturePaymentMutation.Fixable_CARD_CLOSED) {
    errString = 'It looks like the card you attempted to use may have expired or be closed. Please check that and try a different card if that is the case.';
  } else if (errCode
    === ErrorsCapturePaymentMutation.Fixable_REDIRECT_PAYER_FOR_ALTERNATE_FUNDING) {
    errString = 'Hmm, something went wrong; please try that again. If you continue to see this error, we are having a problem charging your card. You may need to try a different debit or credit card.';
  } else if (errCode === ErrorsCapturePaymentMutation.Fixable_CARD_CVC_INVALID) {
    errString = 'It looks like the CVV (security code) you typed in is incorrect. Please check that and try again.';
  } else if (errCode === ErrorsCapturePaymentMutation.Fixable_PAYER_ACCOUNT_LOCKED_OR_CLOSED) {
    errString = 'It looks like your PayPal account has been locked or closed. You may need to contact PayPal, or try using a debit or credit card.';
  } else if (errCode === ErrorsCapturePaymentMutation.Fixable_PAYER_ACCOUNT_RESTRICTED) {
    errString = 'It looks like your PayPal account has been restricted. You may need to contact PayPal, or try using a debit or credit card.';
  } else if (errCode === ErrorsCapturePaymentMutation.Fixable_ORDER_NOT_APPROVED) {
    errString = 'Hmm, something went wrong. Please try clicking the button again. If you continue to see this PLEASE let us know!';
  } else if (errCode === ErrorsCapturePaymentMutation.FixableGeneralError) {
    errString = 'Oops, something went wrong. Please try clicking the button again. If you continue to see this PLEASE let us know!';
  } else if (errCode === ErrorsCapturePaymentMutation.Fixable_GENERAL_CARD_ERROR) {
    errString = 'It looks like the card details you entered might be incorrect. Please check that and try again.';

    // verifyactivate errors
  } else if (errCode === ErrorsVerifyActivateMutation.FixablePaymentfailed) {
    errString = 'Something went wrong when we tried to process your purchase. Please try again.';

    // clicking submit button error
  } else if (errCode === ErrorsClickingOnSubmitButton.FixableInvalidCardFields) {
    errString = 'It looks like one of the fields above is invalid; please check to ensure your card number, CVV (security code), expiration date, and name on the card are correct.';

    // log to sentry, we sent this component an unexpected error code
  } else {
    Sentry.captureException(
      new Error('Important! FixableError got an unexpected errString'),
      {
        extra: {
          dt: errString,
        }
      }
    );
  }

  return (
    <Alert variant="filled" severity="error">
      {errString}
    </Alert>
  );
};
