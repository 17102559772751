import {
  Grid2, Ty
} from '@languageconvo/wcl';
import React from 'react';

interface Props {
  pkgInfo: any
  error: boolean
}

export const OrdersHistoryTableModalPurchdetails2: React.FC<Props> = ({ pkgInfo, error }) => {
  if (error) {
    return null;
  }

  return (
    <>
      <Grid2 xs={6}>
        <Ty><strong>Hours Used</strong></Ty>
        <Ty><strong>Hours Remaining:</strong></Ty>
      </Grid2>
      <Grid2 xs={6}>
        <Ty>{pkgInfo && pkgInfo.hoursUsed}</Ty>
        <Ty>{pkgInfo && pkgInfo.hoursRemaining}</Ty>
      </Grid2>
    </>
  );
};
