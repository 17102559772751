import {
  styled, Box,
} from '@languageconvo/wcl';

// height of each box in the table
// exported because this is used by the loading glimmer
export const boxHeight = '30px';

// column for the left-side headings
export const ColumnContainerHeadings = styled(Box)(() => ({
  width: '12.5%',
  minWidth: '95px',
  float: 'left',
  textAlign: 'center',
}));

// column for day 1, 2, 3...7
export const ColumnContainerTimes = styled(Box)(() => ({
  width: '12.5%',
  minWidth: '95px',
  float: 'left',
  textAlign: 'center',
}));

// for empty boxes at the top of column 1
export const PanelsSingleLessonScheduleAvTimIndividualBox = styled(Box)(({
  times, islast, notAvailable,
} : any) => ({
  ...(times && {
    cursor: 'pointer',
    borderTopColor: '#dadada',
    borderTopStyle: 'solid',
    borderTopWidth: '1px',
    borderRightColor: '#dadada',
    borderRightStyle: 'solid',
    borderRightWidth: '1px',
    // TODO: hover green background
    // '&:hover': {
    //   backgroundColor: `${theme?.ourTheme.colors.cp.bgInnerCard.accentGreen1}`,
    // },
  }),
  ...(notAvailable && {
    pointerEvents: 'none',
    background: '#dadada'
  }),
  ...(islast && {
    borderBottom: '1px solid #dadada'
  }),
  height: boxHeight,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));

// for each box in column1 that is to the left of the table
export const PanelsSingleLessonScheduleAvTimGuidelinecolGridright = styled(Box)(() => ({
  height: boxHeight,
  borderRightColor: '#dadada',
  borderRightStyle: 'solid',
  borderRightWidth: '1px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
}));
