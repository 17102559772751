import React, { useState, useEffect, useLayoutEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useMutation } from 'react-relay';
import {
  Grid2Ct, Grid2, Card, CardContent, Ty, Box, IcSvg, IcSvgList,
  Alert,
} from '@languageconvo/wcl';
import scrollIntoView from 'scroll-into-view-if-needed';
import { StuSettingsUpdatetrialstep3GetavailabilityMutation } from '../../../../relay/posttrial/StuSettingsUpdatetrialstep3';
import { Step3Availability } from './StepsToSchedule4Step3/Step3Availability';
import { Step3AvailabilityLoading } from './StepsToSchedule4Step3/Step3AvailabilityLoading';
import { StuSettingsGettrialdataMutation } from '../../../../../../../common/relay/mutations/students/StuSettingsGettrialdata';

interface Props {
  langId: number
}

// select and schedule a time for a free trial lesson
export const StepsToSchedule4Step3 = ({ langId }: Props) => {
  // State to track errors in case the mutation fails
  const [error, setError] = useState();
  useEffect(() => {
    if (error) {
      throw new Error(error);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error]);

  // #region get availability

  const [AvailabilityData] = useMutation(StuSettingsUpdatetrialstep3GetavailabilityMutation);
  // schedData will hold the data about teacher availability that we get back from our api call
  const [schedData, setSchedData] = useState<any>([]);
  const [schedulingError, setSchedulingError] = useState('');

  // If user change lng while standing on step3, and we are not offeringn any trails for that
  // selected lng, In this case, we make mutation call again with that new lng which will handle
  // everything own its own with relay.
  const [TheTrialData] = useMutation(StuSettingsGettrialdataMutation);
  const gettrialData = () => {
    TheTrialData({
      variables: {
        languageId: langId,
      },
      onCompleted() {
      // success, don't need to do anything
      },
      onError(err: any) {
        Sentry.captureException(err);
        setError(err);
      }
    });
  };

  const mutationCallToGetAvailabilityData = () => {
    AvailabilityData({
      variables: {
        languageId: langId,
      },
      onCompleted(dt: any) {
        const parsedSchedData = JSON.parse(dt.stu_appt_gettrialavailability.schedule);
        setSchedData(parsedSchedData);
        // handle scnario if no trial available for user selected lng then execute trial
        // mutaiton again for new lang that user has changed.
        if (parsedSchedData.length === 0) {
          gettrialData();
        }
      },
      onError(err: any) {
        Sentry.captureException(err);
        setError(err);
      }
    });
  };

  useEffect(() => {
    setSchedData([]);
    mutationCallToGetAvailabilityData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [langId]);

  // #endregion

  // #region to scroll to top

  useLayoutEffect(() => {
    const scroolToTop = document.getElementById('step3');
    scrollIntoView(scroolToTop!, {
      behavior: 'auto',
      // block: center attempts to vertically center the element in the viewport
      block: 'center',
      inline: 'nearest',
    });
  }, []);

  // #endregion

  return (
    <Grid2Ct>
      <Grid2 xs={12}>
        <Card>
          <CardContent>
            <Grid2Ct>
              {/* title and subtitle */}
              <Grid2 xs={12} display="flex" alignContent="center" justifyContent="center">
                <IcSvg width="40px" height="40px" icon={IcSvgList.calendar2} />&nbsp;&nbsp;&nbsp;&nbsp;<Ty v="h1New" removeMb cp={{ id: 'step3' }}>Step 3 of 3</Ty>
              </Grid2>
              <Grid2 xs={12}>
                <Box display="flex" justifyContent="center">
                  <Ty v="h2New">Schedule a Free Trial Lesson!</Ty>
                </Box>
              </Grid2>

              <Grid2 xs={12} sm={10} smOffset={1}>
                {schedulingError === 'SchedulingConflict' && (
                  <Alert variant="standard" title="Oops! That Time Was Taken" severity="info">
                    We apologize, it looks like another student scheduled that time before you
                    were able to. We&apos;ve reloaded the schedule to have the most up-to-date
                    times. Please choose a new time, our apologies for the inconvenience!
                  </Alert>
                )}
                {schedulingError === 'GeneralError' && (
                  <Alert variant="standard" title="Oops! Something went wrong" severity="info">
                    Hmm something went wrong, please try reloading the page
                  </Alert>
                )}
                {schedulingError === 'AlreadyScheduled' && (
                  <Alert variant="standard" title="Oops! Lesson Already Scheduled" severity="info">
                    It looks like you have already scheduled the lesson.
                    You can only schedule 1 Lesson at 1 time
                  </Alert>
                )}
              </Grid2>

              {/* calendar of availability */}
              {schedData?.length > 0
                ? (
                  <Step3Availability
                    sched={schedData}
                    mutationCallToGetAvailabilityData={mutationCallToGetAvailabilityData}
                    setSchedData={setSchedData}
                    setSchedulingError={setSchedulingError}
                  />
                ) : (<Step3AvailabilityLoading />)}
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2Ct>
  );
};
