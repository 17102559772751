import React, { useState } from 'react';
import {
  Grid2, Grid2Ct, Ty, CardStandard, Dialog, CardSmallColor, Link, IcSvgList,
} from '@languageconvo/wcl';
import {
  NavLink
} from 'react-router-dom';
import { sitedata } from '../../utils/sitedata';

export const PracticeHome = () => {
  // #region setup data

  // click to go to the easylistening page
  const clickableTypeObj = {
    to: sitedata.url.app.practiceEasylistening,
    component: NavLink,
  };

  // click to go to the curriculum page
  const clickableTypeObj2 = {
    to: sitedata.url.app.practiceCurriculum,
    component: NavLink,
  };

  // #endregion

  // #region under construction modal

  // construction modal state. true=open, false=closed. closed by default
  const [constructionModalState, setConstructionModalState] = useState<boolean>(false);

  // clicks on the element to open the construction modal
  const handleConstructionModalOpen = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setConstructionModalState(true);
  };

  // construction modal close action
  const handleConstructionModalClose = () => {
    setConstructionModalState(false);
  };

  // #endregion

  return (
    <Grid2Ct columns={20}>
      {/* card - easylistening */}
      <Grid2 xs={20} sm={16} smOffset={2} md={14} mdOffset={3} xl={10} xlOffset={5}>
        <CardStandard
          clickableLink1={{ ...clickableTypeObj }}
          titleText="EasyListening Practice"
          titleIcon={IcSvgList.music1}
          color="accentPurple1"
        >
          {/* we wrap the entire children/contents in a link so that it's also clickable, making
          it easy for the user to click almost anywhere in the card */}
          <Link
            to={sitedata.url.app.practiceEasylistening}
            component={NavLink}
            linkStyle="nostyle"
          >
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty>Type words, phrases, even entire paragraphs, and listen to how it
                  sounds in the language you are learning!
                </Ty>
              </Grid2>
            </Grid2Ct>
          </Link>
        </CardStandard>
      </Grid2>

      {/* card - complete curriculum */}
      <Grid2 xs={20} sm={16} smOffset={2} md={14} mdOffset={3} xl={10} xlOffset={5}>
        <CardStandard
          clickableLink1={{ ...clickableTypeObj2 }}
          titleText="Complete Curriculum"
          titleIcon={IcSvgList.book1}
          color="accentOrange1"
        >
          <Link
            to={sitedata.url.app.practiceCurriculum}
            component={NavLink}
            linkStyle="nostyle"
          >
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty>Get a complete picture of what you are learning, what you have
                  accomplished, and what is coming next.
                </Ty>
              </Grid2>
            </Grid2Ct>
          </Link>

          <Grid2Ct>
            <Grid2 xs={12} sm={10} md={8} lg={8} xl={9}>
              <CardSmallColor
                text="Under Construction!"
                icon={IcSvgList.trafficcone1_accentOrange1}
                color="accentOrange1"
                onClick={handleConstructionModalOpen}
                hovercursor="pointer"
              />
            </Grid2>
          </Grid2Ct>
        </CardStandard>
      </Grid2>

      {/* modal - curriculum under construction */}
      <Dialog
        isOpen={constructionModalState}
        onClose={handleConstructionModalClose}
        width="sm"
        color="accentOrange1"
      >
        <Grid2Ct>
          <Grid2 xs={12}>
            <Ty removeMb>
              We are in the process of HUGE updates to the curriculum! In just a few months, we will
              have a completely redesigned curriculum with clear levels, units, goals, etc. that you
              can use with your teacher.
            </Ty>
          </Grid2>
        </Grid2Ct>
      </Dialog>
    </Grid2Ct>
  );
};
