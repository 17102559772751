import React, { useLayoutEffect, useState } from 'react';
import {
  Grid2Ct, Grid2, Card, CardContent, IcSvg, IcSvgList, Ty, Button, Box, TextField, Select,
  Alert,
  SelectChangeEvent,
} from '@languageconvo/wcl';
import * as Sentry from '@sentry/react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from 'react-relay';
import { toast } from 'react-toastify';
import { DateTime, Settings } from 'luxon';
import scrollIntoView from 'scroll-into-view-if-needed';
import { tz } from '../../../../../../../utils/Timezones';
import { Step2ValidationFields } from '../../schema/StepsToScheduleStep2';
import { StuSettingsUpdatetrialstep2Mutation } from '../../../../relay/posttrial/StuSettingsUpdatetrialstep2';
import { AlertContainer } from './StepsToSchedule4Step2.style';

interface TimeZone {
  name: string,
  value: string,
}

interface Props {
  usrId : string
  usrSettId: string | undefined
}

// set 6 more detailed settings/questions
export const StepsToSchedule4Step2 = ({ usrId, usrSettId }: Props) => {
  // #region general data

  const timeZones: TimeZone[] = tz.map((zone) => ({ name: zone.nameDisplay, value: zone.nameDb }));

  const teacherGenderOptions = [
    {
      value: 1,
      name: 'I want the BEST teacher for me, regardless of their gender!',
    },
    {
      value: 2,
      name: 'I prefer a female, but am open to trying a male teacher if they are great for me',
    },
    {
      value: 3,
      name: 'I prefer a male, but am open to trying a female teacher if they are great for me',
    },
    {
      value: 4,
      name: 'I will only work with a female teacher',
    },
    {
      value: 5,
      name: 'I will only work with a male teacher',
    },
  ];

  const teachingStyleOptions = [
    {
      value: 1,
      name: 'Laid back, easygoing',
    },
    {
      value: 2,
      name: 'In the middle of high energy and easygoing',
    },
    {
      value: 3,
      name: 'High energy, fun',
    },
  ];

  const homeworkOptions = [
    {
      value: 1,
      name: 'Yes, most of the time',
    },
    {
      value: 2,
      name: 'Probably, but I might not always have time',
    },
    {
      value: 3,
      name: 'No, usually not',
    },
  ];

  // #endregion

  // #region react hook form, states

  // we have used react-hook-form here so we don't have to manually create the state and manage
  // that state, we pass in the default schema for validations and default values data we want
  // to render on the screen
  const {
    control, handleSubmit, formState: { errors }
  } = useForm <any>({
    reValidateMode: 'onChange',
    mode: 'onBlur',
    resolver: yupResolver(Step2ValidationFields),
    // adding the default values is important so we don't get
    // warning in the console about changing an uncontrolled
    // input field to be controlled
    defaultValues: {
      prefTeacherGender: 0,
      teacherStyle: 0,
      homework: 0,
      timezone: 0
    }
  });

  // these option are required for luxon to show the timezone in correct format
  const option: Intl.DateTimeFormatOptions = {
    hour: 'numeric',
    minute: 'numeric',
    weekday: undefined
  };

  // we will call this function whenever there is a change in user's timezone
  const onTimezoneChange = (e: SelectChangeEvent, onChange: any) => {
    // updating the timezone, so we get updated value on frontend
    onChange(e);
    // making the selected timezone as default timezone using luxon
    Settings.defaultZone = e.target.value;
    // setting the currentTime according 
    setCurrentTime(DateTime.local().toLocaleString({ ...option }));
  };

  // we need this state to show the current time according to user's timezone
  const [currentTime, setCurrentTime] = useState<string>();

  // #endregion

  // #region mutation

  const [UpdateStudentTrialStep2Settings, isInFlight] = useMutation(
    StuSettingsUpdatetrialstep2Mutation
  );

  // this function gets triggered when user click on Continue to Step 3, 
  // it initiates the mutation call
  const saveStudentInfoAndLessonPreferences = (data: any) => {
    setCurrentTime(undefined);
    UpdateStudentTrialStep2Settings({
      variables: {
        studentAge: parseInt(data.age, 10),
        teacherGender: data.prefTeacherGender,
        teachingStyle: data.teacherStyle,
        homework: data.homework,
        password: data.password,
        timezone: data.timezone
      },
      // this creates our optimistic update. we provide the the data we
      // *expect* to be returned by the mutation. whether the mutation succeeds or
      // fails this gets overwritten once the response is received. note that the relay
      // docs say using optimisticResponse is usually better/easier than using
      // optimisticUpdater to accomplish an optimistic update
      optimisticResponse: {
        stu_settings_updatetrialstep2: {
          success: true,
          errors: [],
          data: {
            user: {
              id: usrId,
              // this is obviously not the users real dob. we just add fake data here so that the
              // optimistic response gets set; it will get overwritten by the real response
              dob: '1900-01-01',
              password_set: 1,
              timezone_set: 1,
              pref_teacher_gender: data.prefTeacherGender,
              user_settings: {
                id: usrSettId,
                teacher_style: data.teacherStyle,
                homework: data.homework,
              }
            }
          }
        }
      },
      onCompleted() {
        // success, don't need to do anything
      },
      onError(err: any) {
        Sentry.captureException(err);
        toast.error('Hmm, something went wrong, please try that again.');
      }
    });
  };

  // #endregion

  // #region to scroll to top

  /* noDefaultScroll: React application have some weird scrolling behaviour, which is explained
    below Due to single page (SPA)
    To change the content, we chnage the routes and page does not refresh, in this case browser
    things I am on the same page and here the issue arises. Let's say, we have 1 route, where
    content is larger, so, we need to scroll on this page to see last available content of this
    route. Till now, everything is ok, but one we change route and go to 2nd route, this route
    should not show any already scrolled and it should show content from top of the page but this
    is not happening, we can see the scroll in this 2nd page too. which is really wiered. To
    prevent this behaviour, we are using noDefaultScroll flag, and if it is true, we always
    show page top level content instead of some intermediatery content of the page.

    This code block preventing page default scrolling and always show content from top.
   */
  useLayoutEffect(() => {
    const scroolToTop = document.getElementById('step2');
    scrollIntoView(scroolToTop!, {
      behavior: 'auto',
      // block: center attempts to vertically center the element in the viewport
      block: 'center',
      inline: 'nearest',
    });
  }, []);

  // #endregion

  //  - set a max width on all the input elements
  return (
    <Grid2Ct>
      <Grid2
        xs={12}
        sm={12}
        md={10}
        mdOffset={1}
        lg={10}
        lgOffset={1}
        xl={8}
        xlOffset={2}
      >
        <Card>
          <CardContent>
            <Grid2Ct>
              {/* title and subtitle */}
              <Grid2 xs={12} display="flex" alignContent="center" justifyContent="center">
                <IcSvg width="40px" height="40px" icon={IcSvgList.settings1} />&nbsp;&nbsp;&nbsp;&nbsp;<Ty cp={{ id: 'step2' }} v="h1New" removeMb>Step 2 of 3</Ty>
              </Grid2>
              <Grid2 xs={12}>
                <Box display="flex" justifyContent="center">
                  <Ty v="h2New">Schedule a Free Trial Lesson!</Ty>
                </Box>
              </Grid2>

              {/* age */}
              <ItemGrid>
                <Ty v="subheading">
                  Your Age
                </Ty>
                <Ty>
                  Some teachers work more with adults, other teachers with children or teenagers.
                  What&apos;s the age of the person who will be studying?
                </Ty>
                <Controller
                  name="age"
                  control={control}
                  render={
                  ({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <TextField
                      id="age"
                      type="text"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!error}
                      helpertext={error ? error.message : null}
                      cp={{ sx: { mt: 1 } }}
                    />
                  )
                 }
                />
              </ItemGrid>

              {/* teacher gender */}
              <ItemGrid>
                <Ty v="subheading">
                  Teacher Gender
                </Ty>
                <Ty>
                  We strongly recommend (even for children!) letting us help you
                  find the *best* teacher for you; that said, let us know your
                  teacher gender preference:
                </Ty>
                <Controller
                  name="prefTeacherGender"
                  control={control}
                  render={
                  ({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <Select
                      options={teacherGenderOptions}
                      value={value}
                      helperText={error ? error.message : null}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!error}
                      cp={{ sx: { mt: 1 } }}
                    />
                  )
                 }
                />
              </ItemGrid>

              {/* teaching style */}
              <ItemGrid>
                <Ty v="subheading">
                  Teaching Style
                </Ty>
                <Ty>
                  What teaching style do you *think* you might enjoy best? Don&apos;t worry
                  too much about this, remember we offer unlimited free trial lessons, and
                  most teachers greatly adjust their teaching style for every student.
                </Ty>
                <Controller
                  name="teacherStyle"
                  control={control}
                  render={
                  ({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <Select
                      options={teachingStyleOptions}
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!error}
                      cp={{ sx: { mt: 1 } }}
                      helperText={error ? error.message : null}
                    />
                  )
                 }
                />
              </ItemGrid>

              {/* homework */}
              <ItemGrid>
                <Ty v="subheading">
                  Homework / Practice Exercises?
                </Ty>
                <Ty>
                  Do you think you will want &quot;homework&quot; to work on in-between lessons?
                </Ty>
                <Controller
                  name="homework"
                  control={control}
                  render={
                  ({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <Select
                      options={homeworkOptions}
                      value={value}
                      onChange={onChange}
                      helperText={error ? error.message : null}
                      onBlur={onBlur}
                      error={!!error}
                      cp={{ sx: { mt: 1 } }}
                    />
                  )
                 }
                />
              </ItemGrid>

              {/* password */}
              <ItemGrid>
                <Ty v="subheading">
                  Set a Password
                </Ty>
                <Ty>
                  So that you can log back in to the website; here in your account you can schedule
                  and cancel your lessons 24/7, see your full schedule, and more!
                </Ty>
                <Controller
                  name="password"
                  control={control}
                  render={
                  ({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <TextField
                      id="password"
                      type="password"
                      value={value}
                      onChange={onChange}
                      onBlur={onBlur}
                      error={!!error}
                      helpertext={error ? error.message : null}
                      cp={{ sx: { mt: 1 } }}
                    />
                  )
                 }
                />
              </ItemGrid>

              {/* time zone */}
              <ItemGrid>
                <Ty v="subheading">
                  Set Your Time Zone
                </Ty>
                <Ty>
                  Important! Select your time zone, so that when scheduling / canceling /
                  rescheduling lessons with your teacher the correct time is displayed.
                </Ty>
                <Controller
                  name="timezone"
                  control={control}
                  render={
                  ({ field: { onChange, value, onBlur }, fieldState: { error } }) => (
                    <Select
                      options={timeZones}
                      onChange={(e) => onTimezoneChange(e, onChange)}
                      onBlur={onBlur}
                      value={value}
                      error={!!error}
                      helperText={error ? error.message : null}
                      cp={{ sx: { mt: 1 } }}
                    />
                  )
                 }
                />
              </ItemGrid>
              {/* time zone alert */}
              {currentTime && (
                <ItemGridNotopmargin>
                  <Alert severity="info">
                    It is currently <strong>{currentTime}</strong> in this time zone.
                    Check your clock! If this time is not correct, you have chosen the
                    wrong time zone.
                  </Alert>
                </ItemGridNotopmargin>
              )}
              {/* alert invalid setting */}
              {Object.keys(errors).length > 0 && (
              <ItemGrid>
                <AlertContainer>
                  Oops! It looks like you missed one of the settings above. Scroll up to
                  find and fix that.
                </AlertContainer>
              </ItemGrid>
              )}
              {/* next step button */}
              <Grid2 xs={12} sx={{ mt: 2 }}>
                <Box display="flex" justifyContent="center">
                  <Button
                    color="accentBlue1"
                    size="large"
                    disabled={isInFlight}
                    onClick={handleSubmit(saveStudentInfoAndLessonPreferences)}
                  >Continue to Step 3
                  </Button>
                </Box>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>
    </Grid2Ct>
  );
};

// container for each main input item
const ItemGrid = ({ children }: any) => (
  <Grid2
    xs={12}
    sm={10}
    smOffset={1}
    md={10}
    mdOffset={1}
    lg={10}
    lgOffset={1}
    xl={8}
    xlOffset={2}
    sx={{ mt: 2 }}
  >
    {children}
  </Grid2>
);

// TODO: this should be deleted and just add a prop to ItemGrid, but we can do that in future
// same as ItemGrid without top margin
const ItemGridNotopmargin = ({ children }: any) => (
  <Grid2
    xs={12}
    sm={10}
    smOffset={1}
    md={10}
    mdOffset={1}
    lg={10}
    lgOffset={1}
    xl={8}
    xlOffset={2}
  >
    {children}
  </Grid2>
);
