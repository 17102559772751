export const RelayAppSettingsVals = {
  // root record
  name: 'RelayAppSettings',
  idName: 'id',
  idVal: 'c6c484ba-c6d2-4c2d-8e54-ffc7a4b758fa',

  // backbutton
  backbuttonName: 'backbutton',

  // trialData
  trialIdVal: '281e8196-44b0-4dc8-b901-660d1ea0abdd',
  trialType: 'StuSettingsGettrialdataOutput',
  trialName: 'trialData',

  // groupClass
  groupClassIdVal: 'b028b7cb-201e-45a5-8346-215cba1e9ae7',
  groupClassName: 'groupClass',
  groupClassType: 'GroupClass',
  groupClassHasInteractedName: 'hasInteracted',

  // microphone settings
  micrphoneDetailsIdVal: 'fd0db262-1a92-4e65-b8dc-a0ad0b0b1c23',
  microPhoneDetailsName: 'microphone',
  micrphoneDetailsType: 'MicroPhoneDetails',
  microphoneDetailsIdName: 'id',

  // current microphone
  currentMicIdVal: 'e34b5d78-f56b-4d26-a7d6-114a41357a10',
  currentMicType: 'CurrentMicrophone',
  currentMicName: 'current',
  currenMicrophoneIdName: 'micId',
  currentMicrophoneTitleName: 'title',
  currentMicrohponeMutedName: 'isMuted',

  // all microphones
  allMicIdVal: 'cfd1f3ca-7a32-4a04-b21b-ee653a816b26',
  allMicIdName: 'id',
  allMicType: 'AllAvailableMic',
  allMicName: 'all',
};
