import { useCallback, useEffect, useState } from 'react';
import { RudderAnalytics } from '@rudderstack/analytics-js';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useFragment } from 'react-relay';
import { RudderStackData } from '../relay/fragments/RudderStackData';

const useRudderStackAnalytics = (
  fragmentRefForRudderStackData: any
) => {
  // @ts-ignore type mismatch for RudderAnalytics
  const [analytics, setAnalytics] = useState<RudderAnalytics>(window.rudderanalytics);
  const location = useLocation();
  const RudderStackUserData: any = useFragment(RudderStackData, fragmentRefForRudderStackData);
  const userDetails = RudderStackUserData.users_connection.edges[0].node;
  const [searchParams] = useSearchParams();
  const acctcrt = searchParams.get('acctcrt');

  useEffect(() => {
    if (analytics) {
      setAnalytics(analytics);
    }
  }, [analytics]);

  const reportAnalytics = useCallback(() => {
    if (analytics) {
      // first, call pageview
      analytics.page();

      // then call identify
      analytics.identify(
        // user id (this is users.random_id_public in our db)
        userDetails?.random_id_public,
        // traits
        {
          email: userDetails.email,
          firstName: userDetails.first_name,
          lastName: userDetails.last_name,
        },
      );
      // this event is specifically for the new account creation, if a new account has been created
      // then we will get a new query param from home route.
      // here we are using the useSearchParams at line 12 of this file to get the "acctcrt" value.
      // Then we check if it's value is 1 and the location where we recieved it is home, we track
      // a new event named as "Account Created"
      if (acctcrt === '1' && location.pathname.includes('home')) {
        analytics.track('Account Created');
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [analytics, userDetails, acctcrt]);

  useEffect(() => {
    reportAnalytics();
  }, [location, reportAnalytics]);

  return { analytics };
};

export default useRudderStackAnalytics;
