import React from 'react';
import {
  Ty, Button, Grid2Ct, Grid2, Box, Ic, LottiePlayer, Card, CardContent,
} from '@languageconvo/wcl';
import PandaDeveloper from '../../../../../common/assets/lottie/pandadeveloper.json';
import { sitedata } from '../../../../../utils/sitedata';

// all of the errors that we purposefully throw in the pay page
// remember: if you don't want sentry to log any of these, you need to include them in
//  src/common/errors/IgnoreErrors
export enum PaypageErrors {
  PaypagePackNotFound = 'PaypagePackNotFound'
}

// custom error boundary for the pay page. has a link back to the main purchase pageks
export const ErrBoundaryPaypage = ({ err }: any) => {
  const sentryErrorPrepend = 'Error: ';

  // pack id was not found in the db
  if (err.toString() === `${sentryErrorPrepend}${PaypageErrors.PaypagePackNotFound}`) {
    return (
      <ErrContainer>
        <Ty align="center">
          Hmm, something went wrong; if you continue to see this error PLEASE let us know!
          Please head back and try that again:
        </Ty>
      </ErrContainer>
    );
  }

  // general error, will be shown if one of the above specific errors does not hit
  return (
    <ErrContainer>
      <Ty align="center">
        We notified Patrick our Software Developer Panda. Please try reloading the
        page, that should fix things. If you continue seeing
        this error though <strong>please</strong> let us know so that we can get it fixed.
      </Ty>
    </ErrContainer>
  );
};

// container for all errors
const ErrContainer = ({ children }: any) => (
  <Grid2Ct>
    <Grid2 xs={12}>
      <Card>
        <CardContent>
          <Grid2Ct>
            {/* text, buttons. flex here for equal height on xl */}
            <Grid2 xs={12} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              {/* this flex vertically aligns the content */}
              <Grid2Ct sx={{ display: 'flex', alignItems: 'center' }}>
                <Box>
                  {/* text - header */}
                  <Grid2 xs={12}>
                    <Ty v="h1New" align="center"><Ic color="accentRed1" iconStyle="duotone" iconName="traffic-cone" />&nbsp;&nbsp;Oops - An Error Occurred</Ty>
                  </Grid2>

                  {/* text */}
                  <Grid2 xs={12} sm={8} smOffset={2} lg={6} lgOffset={3} xl={10} xlOffset={1}>
                    {children}
                  </Grid2>

                  {/* button refresh the page */}
                  <Grid2 xs={12}>
                    <Box display="flex" justifyContent="center">
                      <Button
                        color="accentRed1"
                        size="large"
                        href={sitedata.url.app.purchaseBuyNewDt.pathFull}
                      >
                        Purchase Page
                      </Button>
                    </Box>
                  </Grid2>
                </Box>
              </Grid2Ct>
            </Grid2>

            {/* lottie animation. flex here for equal height on xl */}
            <Grid2 xs={12} xl={6} sx={{ display: 'flex', justifyContent: 'center' }}>
              <Grid2Ct>
                {/* lottie panda (sm and above) */}
                <Grid2 xs={12} sx={{ display: { xs: 'none', sm: 'block' } }}>
                  <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                    <LottiePlayer
                      animationSrc={PandaDeveloper}
                      animationHeight={450}
                    />
                  </Box>
                </Grid2>
                {/* lottie panda (xs) */}
                <Grid2 xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                  <Box display="flex" justifyContent="center" sx={{ mt: 2 }}>
                    <LottiePlayer
                      animationSrc={PandaDeveloper}
                      animationHeight={300}
                    />
                  </Box>
                </Grid2>
              </Grid2Ct>
            </Grid2>
          </Grid2Ct>
        </CardContent>
      </Card>
    </Grid2>
  </Grid2Ct>
);
