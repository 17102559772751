import { graphql } from 'babel-plugin-relay/macro';

export const StuSettingsUpdatetrialstep2Mutation = graphql`
  mutation StuSettingsUpdatetrialstep2Mutation(
    $studentAge: Int!
    $teacherGender: Int!
    $teachingStyle: Int!
    $homework: Int!
    $password: String!
    $timezone: String!
  ) {
    stu_settings_updatetrialstep2 (
      studentAge: $studentAge
      teacherGender: $teacherGender
      teachingStyle: $teachingStyle
      homework: $homework
      password: $password
      timezone: $timezone
    ) {
      success
      errors
      data {
        user {
          id
          dob
          password_set
          timezone_set
          pref_teacher_gender
          user_settings {
            id
            teacher_style
            homework
          }
        }
      }
    }
  }
`;
