import React from 'react';
import { useClientQuery, useFragment } from 'react-relay';
import * as Sentry from '@sentry/react';
import { LayoutPosttrial } from './components/posttrial/LayoutPosttrial';
import { StuSettingsGettrialdataClientQuery, StuSettingsGettrialdataTrialFragment } from '../../../common/relay/mutations/students/StuSettingsGettrialdata';
import { StuSettingsGettrialdataTrialFragment$key } from '../../../common/relay/mutations/students/__generated__/StuSettingsGettrialdataTrialFragment.graphql';
import { LayoutNocredits1Getlangdata } from './components/pretrial/LayoutNocredits1Getlangdata';

export const Home = () => {
  // #region get trial data from relay store

  /* As a first step, we get trial data from the relay store, never making an api call
      here. Why? All we need at this point is the hasCredits value. If the user does
      have credits, we display our normal view for paying customer. If the user does
      *not* have credits, the next thing we'll do is make an api call to get updated
      trial data; we have make this api call every time so that we ensure we always
      have updated, correct trial data
  */
  const response: any = useClientQuery(
    StuSettingsGettrialdataClientQuery,
    {}
  );
  const fragmentRef: StuSettingsGettrialdataTrialFragment$key = response.RelayAppSettings;
  const data = useFragment(StuSettingsGettrialdataTrialFragment, fragmentRef);

  // TODO: remove this, it's for testing
  try {
    if (data?.trialData?.hasCredits !== true && data?.trialData?.hasCredits !== false) {
      Sentry.captureException(
        new Error('MAJOR ERROR: on Home page, trialData.hasCredits did not have expected value'),
        {
          extra: {
            dt: data,
          }
        }
      );
    }
  } catch {
    // do nothing
  }

  // #endregion

  // if the user has credits but there is no teacher who is giving trial lesson, 
  // we'll show the normal layout.
  let displayScenario = 2;
  if (data.trialData?.hasCredits === true) {
    displayScenario = 1;
  }

  return (
    displayScenario === 1 ? (<LayoutPosttrial />)
      : (<LayoutNocredits1Getlangdata />)
  );
};
