import React from 'react';
import { useClientQuery, useFragment } from 'react-relay';
import { useOutletContext } from 'react-router-dom';
import { PretrialDataStusettingsFragment } from '../../../../relay/posttrial/PretrialData';
import { PretrialDataStusettingsFragment$key } from '../../../../relay/posttrial/__generated__/PretrialDataStusettingsFragment.graphql';
import { StepsToSchedule4Step1 } from './StepsToSchedule4Step1';
import { StepsToSchedule4Step2 } from './StepsToSchedule4Step2';
import { StepsToSchedule4Step3 } from './StepsToSchedule4Step3';
import { GetAppLevelDataLanglearnFragment } from '../../../../../../../layouts/dashboard/header/components/relay/GetAppLevelData';
import { StuSettingsGettrialdataClientQuery, TeachersAvailibilityFragment } from '../../../../../../../common/relay/mutations/students/StuSettingsGettrialdata';
import { TrialNoTeachersAvailable } from '../TrialNoTeachersAvailable';

interface Props {
  usr: PretrialDataStusettingsFragment$key
}

export const StepsToSchedule3Scenario = ({ usr }: Props) => {
  const { fragmentRefForUserData }: any = useOutletContext();
  const lnglearnRef = fragmentRefForUserData.users_connection.edges[0].node;
  // reading langIdDt(Data) to get user LngId 
  const langIdDt = useFragment(GetAppLevelDataLanglearnFragment, lnglearnRef);
  // this will be used to fire AvailabilityData mutation as student change their language 
  const langId = langIdDt.lang_learning;

  const userData = useFragment(PretrialDataStusettingsFragment, usr);

  /* we need to mange atleastoneteacheravailable for free trials for user selected lang
      that's shy reading data from fragment here.
  */
  const response: any = useClientQuery(
    StuSettingsGettrialdataClientQuery,
    {}
  );
  const fragmentRef: any = response.RelayAppSettings;
  const data = useFragment(TeachersAvailibilityFragment, fragmentRef);

  // #region determine which setup data is completed

  let interestGroupSet = false;
  if (userData.user_settings?.interest_group !== null) {
    interestGroupSet = true;
  }

  let interestPrivateSet = false;
  if (userData.user_settings?.interest_private !== null) {
    interestPrivateSet = true;
  }

  let timezoneSet = false;
  if (userData.timezone_set === 1) {
    timezoneSet = true;
  }

  let passwordSet = false;
  if (userData.password_set === 1) {
    passwordSet = true;
  }

  let dobSet = false;
  if (userData?.dob !== null) {
    dobSet = true;
  }

  let prefTchgenderSet = false;
  if (userData?.pref_teacher_gender !== null) {
    prefTchgenderSet = true;
  }

  let prefHomeworkSet = false;
  if (userData.user_settings?.homework !== null) {
    prefHomeworkSet = true;
  }

  let prefTchstyleSet = false;
  if (userData.user_settings?.teacher_style !== null) {
    prefTchstyleSet = true;
  }

  // #endregion

  // #region determine is user on step 1, 2, or 3

  /* scenario
      1 = step 1 of 3. the user needs to set interest_group and/or interest_private
      2 = step 2 of 3. user needs to set timezone, password, dob, pref_teacher_gender,
        user_settings.homework, user_settings.teacher_style
      3 = step 3 of 3. schedule your trial
  */
  let scenario;
  if (!interestGroupSet || !interestPrivateSet) {
    scenario = 1;
  } else if (!timezoneSet || !passwordSet || !dobSet || !prefTchgenderSet || !prefHomeworkSet
    || !prefTchstyleSet
  ) {
    scenario = 2;
  } else {
    scenario = 3;
  }

  // #endregion

  // display scenario 1, 2, or 3
  if (scenario === 1) {
    // TODO: need to ensure user_settings has a value? We could probably just log an error, not
    // throw, if it's undefined. Because the step1 mutation will still work without this, just the
    // optimisticResponse won't work without it
    return (<StepsToSchedule4Step1 userSetId={userData.user_settings?.id} />);
  }
  if (scenario === 2) {
    // TODO: similar to step 1, log error if either of these IDs don't exist?
    return (<StepsToSchedule4Step2 usrId={userData.id} usrSettId={userData.user_settings?.id} />);
  }
  // if no teacher available for trials, show normal home page.
  if (data.trialData.trial.atleastOneTeacherGivesTrials === false) {
    // TODO: when john is back, we will see why he has used dt.
    return <TrialNoTeachersAvailable dt={1} />;
  }
  return (
    <StepsToSchedule4Step3 langId={langId} />
  );
};
