import React from 'react';
import * as Sentry from '@sentry/react';
import { useFragment, useLazyLoadQuery, useMutation } from 'react-relay';
import { useSearchParams } from 'react-router-dom';
import {
  Ty, styled, Grid2Ct, Grid2, IcSvg, IcSvgList,
  Button,
} from '@languageconvo/wcl';
import { DateTime } from 'luxon';
import {
  ClassroomBasicDataHandRaisValueFragment,
  ClassroomBasicDataLessoninfoFragment,
  ClassroomBasicDataQuery,
  ClassroomBasicDataUserFragment,
} from '../../../../relay/ClassroomBasicData';
import { validateHourCycle } from '../../../../../../../common/utils/DatesTimes/HourCycle';
import { StuApptgroupHandraise } from '../../../../relay/StuApptgroupHandraise';

export const LessonInfo1GetData = () => {
  const [searchParams] = useSearchParams();
  const lsnUuid = searchParams.get('uuid');

  // query. note that this is the same big query that runs when student enters the classroom; it
  // should very very rarely, if ever, need to actually run again bc the data is already in relay
  // TODO: we *should* to be more correct, create a custom specific query just for this component
  const res: any = useLazyLoadQuery(
    ClassroomBasicDataQuery,
    { lessonUuid: lsnUuid },
  );

  // lesson information
  const lessonInfoRef = res.appt_group_connection.edges[0].node;
  const lessonData = useFragment(
    ClassroomBasicDataLessoninfoFragment,
    lessonInfoRef,
  );
  const { starts_at, ends_at } = lessonData;
  const level = lessonData.skills.skills_levels.skills_categories.title_en;
  const lesson_title = `Skill #${lessonData.skills.display_order} - ${lessonData.skills.title_en}`;

  //  user hour cycle
  const userInfoFragmentRef = res.users_connection.edges[0].node;
  const userData = useFragment(ClassroomBasicDataUserFragment, userInfoFragmentRef);
  const hrCy = validateHourCycle(userData.hour_cycle);
  const timeOpts: Intl.DateTimeFormatOptions = {
    timeStyle: 'short',
    hourCycle: hrCy,
  };

  // converting time into localString to show on UI
  const lessonStartTime = DateTime.fromISO(starts_at).toLocaleString(timeOpts);
  const lessonEndTime = DateTime.fromISO(ends_at).toLocaleString(timeOpts);

  // extracting user's hand_raised fragmentRef 
  const handRaisedValueRef = res.appt_group_connection.edges[0].node.appt_group_reservations[0];

  const handRaisedData = useFragment(
    ClassroomBasicDataHandRaisValueFragment,
    handRaisedValueRef
  );

  const { id, hand_raised } = handRaisedData;

  // mutation call to submit student feedback related to audio, this will update our db
  // so we can notify the teacher 
  const [commitMutation] = useMutation(StuApptgroupHandraise);

  /**
   * Submits audio feedback from a student regarding their audio experience with the teacher.
   * 
   * This function performs the following steps:
   * 1. Calls a GraphQL mutation to update the feedback data in the backend.
   * 2. Utilizes an optimistic response to update the UI immediately, assuming the 
   * mutation will succeed.
   * 3. Logs any errors that occur during the mutation process to Sentry for further investigation.
   * 
   * @param handRaisValue - The feedback value indicating the student's audio experience:
   *   1 - The student can hear the teacher clearly.
   *   2 - The student cannot hear the teacher at all.
   *   3 - The student can hear the teacher, but the audio is very low.
   */
  const submitAudioFeedback = (handRaisValue: number) => {
    commitMutation({
      variables: {
        lessonUuid: lsnUuid,
        handVal: handRaisValue
      },
      // this creates our optimistic update. we provide the the data we
      // *expect* to be returned by the mutation. whether the mutation succeeds or
      // fails this gets overwritten once the response is received.
      optimisticResponse: {
        stu_apptgroup_handraise: {
          appt_group_reservations: {
            id,
            hand_raised: handRaisValue
          }
        }
      },
      onCompleted() {
        // nothing needs to be done if mutation is successful
      },
      onError(err: any) {
        // note: this erroring will be rare, so we are choosing not to show the user a notif that
        // the mutation failed
        Sentry.captureException(err);
      },
    });
  };

  return (
    <Grid2Ct>
      {/* general info title */}
      <Grid2 xs={12}>
        <Ty v="h2New">General Information</Ty>
      </Grid2>

      {/* lesson time */}
      <Grid2 xs={12}>
        <AlignIconText>
          <IcSvg icon={IcSvgList.clock1} height="24px" width="24px" />&nbsp;&nbsp;&nbsp;
          <Ty removeMb>
            {lessonStartTime} - {lessonEndTime}
          </Ty>
        </AlignIconText>
      </Grid2>

      {/* lesson topic */}
      <Grid2 xs={12}>
        <AlignIconText>
          <IcSvg icon={IcSvgList.knowledge2} height="24px" width="24px" />&nbsp;&nbsp;&nbsp;
          <Ty removeMb>
            {level}&nbsp;&nbsp;&nbsp;{lesson_title}
          </Ty>
        </AlignIconText>
      </Grid2>

      {/* hand raise, explanation */}
      <Grid2 xs={12} sx={{ mt: 2 }}>
        <Ty v="h2New">Notify Teacher of Audio Problem</Ty>
        <Ty>
          If you cannot hear your teacher well, please click one of the first two buttons below
          to notify your teacher! Remember that during the lecture portion of the class your teacher
          cannot hear you or any other student, so these buttons are the only way to
          &quot;notify&quot; them of a problem.
          <br /><br />
          Once your teacher has fixed the problem,
          please click the last button to let them know that they fixed the problem.
        </Ty>
      </Grid2>

      {/* cant hear at all */}
      <Grid2 xs={12}>
        <Button color={hand_raised === 2 ? 'accentPurple1Dark' : 'accentPurple1'} fullWidth onClick={() => submitAudioFeedback(2)}>
          I can not hear my teacher at all
        </Button>
      </Grid2>

      {/* can hear, but audio is not good */}
      <Grid2 xs={12}>
        <Button color={hand_raised === 3 ? 'accentPurple1Dark' : 'accentPurple1'} fullWidth onClick={() => submitAudioFeedback(3)}>
          I can hear my teacher, but the audio is poor
        </Button>
      </Grid2>

      {/* can hear */}
      <Grid2 xs={12}>
        <Button color={hand_raised === 1 ? 'accentPurple1Dark' : 'accentPurple1'} fullWidth onClick={() => submitAudioFeedback(1)}>
          I CAN hear my teacher well, great!
        </Button>
      </Grid2>
    </Grid2Ct>
  );
};

const AlignIconText = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));
