import React, { Suspense } from 'react';
import {
  NavLink,
} from 'react-router-dom';
import {
  Grid2Ct, Grid2, Card, CardContent, IcSvg, IcSvgList, Ty, Box,
  CardStandard, Link,
} from '@languageconvo/wcl';
import { sitedata } from '../../../../../../utils/sitedata';
import { UpComingLessonInfo } from './UpComingLessonInfo';
import { UpComingLessonLoader } from './UpComingLessonInfoLoader';

export const TrialUpcoming = () => {
  // link to my schedule
  const clickableTypeObjMyschedule = {
    to: sitedata.url.app.privatelessonsMycalendar,
    component: NavLink,
  };

  return (
    <Grid2Ct>
      {/* upcoming free trial lesson */}
      <Grid2 xs={12} xl={10} xlOffset={1}>
        <Card>
          <CardContent>
            <Grid2Ct sx={{ mb: 1 }}>
              {/* title */}
              <Grid2 xs={12} display="flex" alignContent="center" justifyContent="center">
                <IcSvg width="40px" height="40px" icon={IcSvgList.teacher1} />&nbsp;&nbsp;&nbsp;&nbsp;<Ty v="h1New" removeMb>Upcoming Free Trial Lesson!</Ty>
              </Grid2>

              {/* date and time, how to attend */}
              <Grid2 xs={12} sx={{ mt: 4 }}>
                <Grid2Ct>
                  {/* date and time */}
                  <Grid2 xs={12} md={6}>
                    <Grid2Ct>
                      {/* title */}
                      <Grid2 xs={12}>
                        <Box display="flex" justifyContent="center">
                          <IcSvg width="24px" height="24px" icon={IcSvgList.calendar1} />&nbsp;&nbsp;&nbsp;&nbsp;<Ty v="h2New">Date and Time</Ty>
                        </Box>
                      </Grid2>
                      <Suspense fallback={<UpComingLessonLoader />}>
                        <UpComingLessonInfo />
                      </Suspense>
                    </Grid2Ct>
                  </Grid2>

                  {/* how to attend */}
                  <Grid2
                    xs={12}
                    md={6}
                    sx={{ mt: { xs: 4, md: 0 } }}
                  >
                    <Grid2Ct columns={20}>
                      {/* title */}
                      <Grid2 xs={20}>
                        <Box display="flex" justifyContent="center">
                          <IcSvg width="24px" height="24px" icon={IcSvgList.desk1} />&nbsp;&nbsp;&nbsp;&nbsp;<Ty v="h2New">How To Attend</Ty>
                        </Box>
                      </Grid2>

                      {/* text */}
                      <Grid2
                        xs={20}
                        sm={16}
                        smOffset={2}
                        md={19}
                        mdOffset={0}
                        lg={19}
                        lgOffset={0}
                        xl={16}
                        xlOffset={2}
                      >
                        <Box display="flex" justifyContent="center">
                          <Ty>
                            A few minutes before your lesson time, just head back here to
                            our website. Click the &quot;Enter Class!&quot; button that you
                            see to your left, and you&apos;ll meet with your teacher! You
                            can use a computer, phone, or tablet, and you do not need to download
                            any software.
                          </Ty>
                        </Box>
                      </Grid2>
                    </Grid2Ct>
                  </Grid2>
                </Grid2Ct>
              </Grid2>
            </Grid2Ct>
          </CardContent>
        </Card>
      </Grid2>

      {/* unlimited trials */}
      <Grid2
        xs={12}
        md={6}
        xl={5}
        xlOffset={1}
        // to make this and the cancel card equal heights
        sx={{
          display: 'flex',
        }}
      >
        <CardStandard
          titleText="Unlimited Trial Lessons"
          titleIcon={IcSvgList.gift1}
          color="accentBlue1"
          titleIconRight={0}
          cp={{ sx: { width: '100%' } }} // needed when using display flex on parent
        >
          <Grid2Ct>
            <Grid2 xs={12}>
              <Ty>
                We offer *unlimited* free trial lessons, so that you can find a teacher
                who is a perfect fit for you. As long as you don&apos;t miss this lesson
                you can schedule more if this teacher isn&apos;t a perfect fit (we
                do a really good job of matching you with a teacher though!)
              </Ty>
            </Grid2>
          </Grid2Ct>
        </CardStandard>
      </Grid2>

      {/* need to cancel */}
      <Grid2
        xs={12}
        md={6}
        xl={5}
        // to make this and the cancel card equal heights
        sx={{
          display: 'flex',
        }}
      >
        <CardStandard
          titleText="Need to Cancel?"
          titleIcon={IcSvgList.calendar5}
          color="accentRed1"
          clickableLink1={{ ...clickableTypeObjMyschedule }}
          cp={{ sx: { width: '100%' } }} // needed when using display flex on parent
        >
          <Link
            to={sitedata.url.app.privatelessonsMycalendar}
            component={NavLink}
            linkStyle="nostyle"
          >
            <Grid2Ct>
              <Grid2 xs={12}>
                <Ty>
                  Click this box if you cannot make it to your lesson, then cancel on the
                  next page. Please cancel at least 24 hours in advance; if you cancel
                  with less notice than that, we won&apos;t be able to offer you
                  another free trial lesson.
                </Ty>
              </Grid2>
            </Grid2Ct>
          </Link>
        </CardStandard>
      </Grid2>
    </Grid2Ct>
  );
};
