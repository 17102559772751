import React, { useMemo } from 'react';
import { PayPalHostedField, PayPalHostedFieldsProvider } from '@paypal/react-paypal-js';
import {
  useTheme, useMediaQuery,
} from '@languageconvo/wcl';
import { SubmitPayment } from './SubmitPayment';
import { PayPalHostedFields } from './PayPalHostedFields';

// payPalFields component renders PayPal hosted fields and a payment submit button
// memoize the PayPal fields to prevent unnecessary re-renders and maintain consistent field state
export const PayPalFields = ({
  cardHolderName, cardHolderLastName, createOrderMutation
}: any) => {
  // #region styles for the hosted fields

  // IMPORTANT #1: if you edit these styles, dont forget to edit the styles of the first and
  //  last name inputs. they are not "hosted fields" so do not get these styles
  //
  // IMPORTANT #2: we have to set some styles here, but others have to be set on the fields
  //  themselves. the only way to really know where to set which is trial and error. the
  //  PayPalHostedFields is the other place styles are set
  //
  // IMPORTANT #3: not all css styles are supported. see: 
  // https://developer.paypal.com/docs/checkout/advanced/style-card-fields/
  // for a list. for example, we have to use outline instaed of borderColor
  const theme = useTheme();
  const fontColor = `hsl(${theme.ourTheme.colors.text.base})`;
  const screensizeMd = useMediaQuery(theme.breakpoints.up('md'));
  let theFontSize = `${theme.ourTheme.typography.p.fontSizeRemSmallscrn}rem`;
  if (screensizeMd) {
    theFontSize = `${theme.ourTheme.typography.p.fontSizeRem}rem`;
  }
  const theFont = `${theFontSize} ${theme.ourTheme.typography.fontFamily}`;

  // #endregion

  const HostedFields = useMemo(() => (
    <PayPalHostedFieldsProvider
      styles={{
        '.invalid': { color: '#dc3545' },
        input: {
          font: theFont,
          color: fontColor,
        },
      }}
      createOrder={
        async () => {
          const paymentId = await createOrderMutation();
          if (paymentId !== null) {
            return paymentId;
          }
          // this will happen only if the backend returned any error in case of createOrderMutation
          return '';
        }
      }
    >
      {/* card number, cvv, expiry date */}
      <PayPalHostedFields
        PayPalHostedField={PayPalHostedField}
      />

      {/* first name, last name, submit button */}
      <SubmitPayment
        cardHolderName={cardHolderName}
        cardHolderLastName={cardHolderLastName}
      />
    </PayPalHostedFieldsProvider>

    // eslint-disable-next-line react-hooks/exhaustive-deps
  ), []);

  return HostedFields;
};
