import React, { useState, useEffect } from 'react';
import * as Sentry from '@sentry/react';
import {
  Ty, Dialog, Grid2Ct, Grid2, Skeleton,
} from '@languageconvo/wcl';
import { useMutation } from 'react-relay';
import { OrdersHistoryTableModalPurchdetails2 } from './OrdersHistoryTableModalPurchdetails2';
import { GetPkgDetailsMutation } from '../relay/GetPkgDetailsMutation';

export const OrdersHistoryTableModalPurchdetails1 = (props :any) => {
  // #region general

  const { anchorEl, handleClose, refNumber } = props;
  const open = Boolean(anchorEl);

  const [commitMutation, isInFlight] = useMutation(GetPkgDetailsMutation);
  // state to hold pkgInformation
  const [pkgInfo, setPkgInfo] = useState();
  // state to track mutation is fail.
  const [error, setError] = useState<boolean>(false);

  // #endregion

  // #region mutation

  // here we are executing the mutaion call 
  useEffect(() => {
    commitMutation({
      variables: {
        invoiceNumber: refNumber
      },
      onCompleted(res: any) {
        setPkgInfo(res.stu_orders_getindividualpkgdetails.data);
      },
      onError(err: any) {
        setError(true);
        Sentry.captureException(err);
      },
    });

    return () => {
      setError(false);
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refNumber]);

  // #endregion

  return (
    <Dialog
      isOpen={open}
      onClose={handleClose}
      width="xs"
      color="accentPurple1"
    >
      <Grid2Ct>
        <Grid2 xs={12}>
          <Ty v="h2New" align="center">Package Details</Ty>
        </Grid2>

        {/* if an error occurred with the mutatoin */}
        {error && (
          <Grid2 xs={12}>
            <Ty align="center">
              Hmm, something went wrong. Please try that again.
            </Ty>
          </Grid2>
        )}

        {/* loading state, and details once the mutation finishes loading */}
        {isInFlight ? (
          <Grid2 xs={12}>
            <Skeleton variant="text" />
            <Skeleton variant="text" />
          </Grid2>
        ) : (<OrdersHistoryTableModalPurchdetails2 pkgInfo={pkgInfo} error={error} />)}
      </Grid2Ct>
    </Dialog>
  );
};
