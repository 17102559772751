import React, { useState, useLayoutEffect } from 'react';
import * as Sentry from '@sentry/react';
import { useLazyLoadQuery, useFragment } from 'react-relay';
import { DateTime } from 'luxon';
import {
  Grid2Ct, Grid2,
} from '@languageconvo/wcl';
import { Step1Subscription } from './components/main/Step1Subscription';
import { Header } from './components/Header';
import {
  PageloadQuery,
  PageloadLanglearnFragment,
  PageloadTimesettingsFragment
} from './relay/Pageload';
import { PageloadLanglearnFragment$key } from './relay/__generated__/PageloadLanglearnFragment.graphql';
import { PageloadTimesettingsFragment$key } from './relay/__generated__/PageloadTimesettingsFragment.graphql';
import { LanguageNotAvailableYet } from './components/LanguageNotAvailableYet';

export const GroupSchedule = () => {
  // #region general

  // dont allow access to this pagein prod, yet
  if (process.env.REACT_APP_ENV === 'prod') {
    throw new Error('no prod access');
  }

  // get the lang_learning value (the language the user is learning) and their hour_cycle.
  // we need lang_learning to ensure we subscribe to lessons of the language the user is
  // learning, and hour_cycle to display lesson times correctly
  const response: any = useLazyLoadQuery(
    PageloadQuery,
    {}
  );
  const fragmentRef: PageloadLanglearnFragment$key = response.users_connection.edges[0].node;
  const data = useFragment(PageloadLanglearnFragment, fragmentRef);
  const langlearnId = data.lang_learning;
  const fragmentRefTs: PageloadTimesettingsFragment$key = response.users_connection.edges[0].node;
  const dataTs = useFragment(PageloadTimesettingsFragment, fragmentRefTs);
  const hourCy = dataTs.hour_cycle;
  const timedisplaySettings = {
    hourCycle: hourCy,
  };

  // daysSelector is an object which holds the day the user has selected (today, tomorrow or
  // +2 days in future) to view upcoming lessons. it also contains the timestamps that
  // define those days. see the Type for details
  const [daysSelector, setDaysSelector] = useState<DaysSelector>(fakeInitValues);
  // on pageload, we set the selected day to "today" (1)
  useLayoutEffect(() => {
    calculateAndSetDaysSelector(1, daysSelector, setDaysSelector);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // don't let code continue until we've set the real initial values for daysSelector
  if (daysSelector.selectedDay.startTs === 1) {
    return <div />;
  }

  // #endregion

  // continue to subscription
  return (
    <Grid2Ct sx={{ mb: 3 }}>
      <Grid2 xs={12} xl={10} xlOffset={1}>
        <Header daysSelector={daysSelector} setDaysSelector={setDaysSelector} />

        { langlearnId === 1 ? (
          <Step1Subscription
            languageId={langlearnId}
            tsSettings={timedisplaySettings}
            daysSelector={daysSelector}
            setDaysSelector={setDaysSelector}
          />
        ) : (<LanguageNotAvailableYet />) }
      </Grid2>
    </Grid2Ct>
  );
};

// #region daysSelector

/* daysSelector is our variable that contains 2 pieces of important information which we
    will use throughout this page:

    1. What day has the user selected to view? Today, tomorrow, or the next day? This
        is stored in selectedDay
    2. What are the start and end timestamps of today (day1), tomorrow (day2), and
       +2 days in the future (day3)

    Notice that for each of those, we have startTs and endTs. These are the unix timestamps
    (in milliseconds -- because with seconds, luxon creates decimal values which is annoying
    to deal with). One thing we do is always ensure that selectedDay matches either day1, day2,
    or day3; selectedDay can't ever be values other than one of those three.
*/
export interface DaysSelector {
  selectedDay: {
    startTs: number;
    endTs: number;
  }
  day1: {
    startTs: number;
    endTs: number;
  }
  day2: {
    startTs: number;
    endTs: number;
  }
  day3: {
    startTs: number;
    endTs: number;
  }
}

/* Which day the user is selecting: 
  0 = the user has not selected any day at all. 0 occurs in two situations:
      1. the user opened the day selector modal
      2. our time determines a new day has started
  1 = today
  2 = tomorrow
  3 = +2 days in future
*/
type DaySelected = 1 | 2 | 3 | 0;

/* Set daysSelector in our state var
    As noted in the interface, day1 is always today, day2 is always tomorrow, day3 is always
    two days from now. And also importantly, selectedDay *must* be one of those days.
*/
export const calculateAndSetDaysSelector = (
  daySelected : DaySelected,
  daysSelector: DaysSelector,
  setDaysSelector : any,
) => {
  // calculate the start and end timestamps (unix milliseconds values) of the start
  // of today (day1), tomorrow (day2), and the next day (day3)
  const dtNow = DateTime.now();
  const day1Start = dtNow.startOf('day').toMillis();
  const day1End = dtNow.endOf('day').toMillis();
  const day2Start = dtNow.plus({ days: 1 }).startOf('day').toMillis();
  const day2End = dtNow.plus({ days: 1 }).endOf('day').toMillis();
  const day3Start = dtNow.plus({ days: 2 }).startOf('day').toMillis();
  const day3End = dtNow.plus({ days: 2 }).endOf('day').toMillis();

  // #region selectedDay

  // selectedDay is the day the user has chosen in the modal; see the DaySelected type for details

  let selectedDayStart;
  let selectedDayEnd;
  // the user has selected "today" (or the user loaded the page; we set today by default on load)
  if (daySelected === 1) {
    selectedDayStart = day1Start;
    selectedDayEnd = day1End;

    // user has selected to view tomorrow 
  } else if (daySelected === 2) {
    selectedDayStart = day2Start;
    selectedDayEnd = day2End;

    // user has selected to view 2 days from today
  } else if (daySelected === 3) {
    selectedDayStart = day3Start;
    selectedDayEnd = day3End;

    /* The user has not selected a day. This is used in two places:
          1. the user opened the day selector modal
          2. our time determines a new day has started
        We're ensuring that the user the day has selected, is one of the three days
        (today/tomorrow/+2 days) in our list. If it is not, the very likely scenario
        is that a new day has started, and for some reason our code which detects that and
        updates this daysSelector hasn't successfully run. If the day the user has selected
        is NOT one of the 3 days, we set it to one of the 3 days. This way we never have a case
        where the selected day is not one of today, tomorrow, or +2 days
    */
  } else if (daySelected === 0) {
    if (daysSelector.selectedDay.startTs === day1Start) {
      selectedDayStart = day1Start;
      selectedDayEnd = day1End;
    } else if (daysSelector.selectedDay.startTs === day2Start) {
      selectedDayStart = day2Start;
      selectedDayEnd = day2End;
    } else if (daysSelector.selectedDay.startTs === day3Start) {
      selectedDayStart = day3Start;
      selectedDayEnd = day3End;

      // the day the user has selected is not one of day1/day2/day3, so set it to today. this
      // could happen if a new day has started, or possibly the user left the page open for
      // more than 3 days but the js hasn't been running. now the user opens the modal and the
      // day they had selected is way in the past
    } else {
      selectedDayStart = day1Start;
      selectedDayEnd = day1End;
    }

    // should never happen. log, and set today as the selected days
  } else {
    Sentry.captureException(
      new Error('in calculateAndSetDaysSelector, daySelected value is unexpected'),
      {
        extra: {
          daySelectedVal: daySelected,
        }
      }
    );
    selectedDayStart = day1Start;
    selectedDayEnd = day1End;
  }

  // #endregion

  // finally, actually set daysSelector values in our state var
  const dt: DaysSelector = {
    selectedDay: {
      startTs: selectedDayStart,
      endTs: selectedDayEnd,
    },
    day1: {
      startTs: day1Start,
      endTs: day1End,
    },
    day2: {
      startTs: day2Start,
      endTs: day2End,
    },
    day3: {
      startTs: day3Start,
      endTs: day3End,
    }
  };
  setDaysSelector(dt);
};

// fake initial values for daysSelector, which the useState sets. we do because we want
// daysSelector to always have the shape defined in our DaysSelector interface rather than
// being null. why? by forcing daysSelector to *always* have the shape of DaysSelector
// interface, our code is simpler (we don't have to do daysSelector?.selectedDay with a question
// mark, we can always just do daysSelector.selectedDay)
const fakeInitValues = {
  selectedDay: {
    startTs: 1,
    endTs: 1,
  },
  day1: {
    startTs: 1,
    endTs: 1,
  },
  day2: {
    startTs: 1,
    endTs: 1,
  },
  day3: {
    startTs: 1,
    endTs: 1,
  }
};

// #endregion
