import React, { useState } from 'react';
import {
  Grid2, Grid2Ct, Ty, IcSvgList, CardSmallColor, Dialog, Box,
} from '@languageconvo/wcl';
import { SmallcardMaxWidthContain } from '../common/PurchaseCommon';

export const PurchaseGroup = () => {
  // select package
  const [modalStatePkg, setModalStatePkg] = useState<boolean>(false);
  const handleModalOpenPkg = (event: any) => {
    event.preventDefault(); // prevents onClick from auto-reloading
    setModalStatePkg(true);
  };
  const handleModalClosePkg = () => {
    setModalStatePkg(false);
  };

  return (
    <>
      <Grid2Ct sx={{ mt: { xs: 1, sm: 2 } }}>
        <Grid2 xs={12}>
          <Ty>
            Group classes are in final testing phases and will be available for everyone
            soon!
          </Ty>
          <Box display="flex" justifyContent="center">
            <SmallcardMaxWidthContain>
              <CardSmallColor
                text="Learn More"
                icon={IcSvgList.earth1}
                color="accentBlue1"
                cp={{ sx: { mt: 2 } }}
                hovercursor="pointer"
                onClick={handleModalOpenPkg}
              />
            </SmallcardMaxWidthContain>
          </Box>
        </Grid2>
      </Grid2Ct>

      <Dialog
        isOpen={modalStatePkg}
        onClose={handleModalClosePkg}
        width="sm"
        color="accentBlue1"
      >
        <Ty>
          Group classes are what you might guess &mdash; you, one of our professional
          teachers, and some other students. There will be numerous classes every day,
          each will have a different topic and be geared toward different ability levels.
          <br /><br />
          The general format will be that for the first part of the class, your teacher will
          teach you something. Then in the second part of the class, you will practice using
          that skill talking to other students in the class!
          <br /><br />
          In total there can be a maximum of 8 students (including you), but that will be
          split into two smaller groups of 4. That way you have ample opportunity to
          practice speaking which is the BIG key to learning a language!
        </Ty>
      </Dialog>
    </>
  );
};
