import * as Sentry from '@sentry/react';

export const GenerateClientToken = ({
  setClientToken, setClientTokenError,
  ClientTokenMutation, setIsGenerateTokenMutationInFlight
}: any) => {
  ClientTokenMutation({
    variables: {},
    onCompleted(res: any) {
      // we expect the backend to return the success true, that's why we are checking here
      // if the success is true, we will set our state otherwise we will log error in sentry
      // and throw user to page level error
      if (res.orders_generatepaypalclienttoken?.success === true) {
        // we also expect the backend to never return null, if it does return null we are 
        // logging error in sentry and throwing user to page level error
        if (res.orders_generatepaypalclienttoken.data.clientToken === null) {
          Sentry.captureException(
            new Error('Important! in generateClientToken mutation 200 response came from backend, success is true but client token is null'),
            {
              extra: {
                response: res,
              }
            }
          );
          setClientTokenError(true);
          setIsGenerateTokenMutationInFlight(false);
        } else {
          setClientToken(res.orders_generatepaypalclienttoken.data.clientToken);
          setIsGenerateTokenMutationInFlight(false);
        }
      } else {
        Sentry.captureException(
          new Error('Important! in generateClientToken mutation 200 response came from backend, but there is no success true field'),
          {
            extra: {
              response: res,
            }
          }
        );
        setClientTokenError(true);
        setIsGenerateTokenMutationInFlight(false);
      }
    },
    onError(err: any) {
      Sentry.captureException(err);
      setClientTokenError(true);
      setIsGenerateTokenMutationInFlight(false);
    },
  });
};
